import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { styled } from '@mui/styles';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { URL } from '../constants';

const GreyTextfield = styled(TextField)(({ theme }) => ({
	background: '#F9F9FB',
	borderRadius: '5px',
	border: 'none',
	'&:hover': {
		border: 'none',
	},
	'& .MuiOutlinedInput-root': {
		background: '#F9F9FB',
		border: 'none',
		'& fieldset': {
			'&:hover': {
				border: 'none',
			},
		},
	},
}));

const requestQuoteSchema = z.object({
	email: z.string().email(),
	first_name: z.string().min(2, 'First Name is too short'),
	last_name: z.string().min(2, 'Last Name is too short'),
	phone_number: z.string().regex(/^[0-9+\-\s]*$/, {
		message: 'Enter a valid phone number',
	}),
	message: z.string().min(10, 'Please enter a longer message'),
});

const RequestAQuote = ({ id, withMargin = true }) => {
	const {
		register,
		formState: { errors },
		reset,
		handleSubmit,
	} = useForm({
		resolver: zodResolver(requestQuoteSchema),
	});
	const [isRequestLoading, setIsRequestLoading] = useState(false);

	const onSubmit = async (values) => {
		try {
			setIsRequestLoading(true);
			await axios.post(`${URL}professionals/create-quote/`, { profession: id, ...values });
			reset();
			toast.success('Quote requested successfully');
		} catch (error) {
			toast.error('Could not request quote');
		} finally {
			setIsRequestLoading(false);
		}
	};
	return (
		<Stack
			p='1.5rem'
			gap='1rem'
			component='form'
			onSubmit={handleSubmit(onSubmit)}
			sx={{
				// position: 'sticky',
				// top: '100px',
				marginTop: withMargin
					? {
							sm: 0,
							xs: 0,
							md: '-8rem',
							lg: '-8rem',
					  }
					: {},
				background: withMargin ? 'white' : 'transparent',
				borderRadius: '4px',
			}}
		>
			<GreyTextfield
				size='small'
				placeholder='First name'
				color='secondary'
				{...register('first_name')}
				helperText={errors?.first_name?.message}
				error={!!errors?.first_name}
			/>
			<GreyTextfield
				size='small'
				placeholder='Last name'
				color='secondary'
				{...register('last_name')}
				helperText={errors?.last_name?.message}
				error={!!errors?.last_name}
			/>
			<GreyTextfield
				size='small'
				placeholder='Email'
				color='secondary'
				{...register('email')}
				helperText={errors?.email?.message}
				error={!!errors?.email}
			/>
			<GreyTextfield
				size='small'
				placeholder='Phone Number'
				color='secondary'
				{...register('phone_number')}
				helperText={errors?.phone_number?.message}
				error={!!errors?.phone_number}
			/>
			<GreyTextfield
				size='small'
				placeholder='Type your message'
				multiline
				rows={4}
				maxRows={5}
				color='secondary'
				{...register('message')}
				helperText={errors?.message?.message}
				error={!!errors?.message}
			/>
			<LoadingButton
				loading={isRequestLoading}
				variant='contained'
				sx={{ color: 'white', textTransform: 'capitalize', fontSize: '16px' }}
				type='submit'
			>
				Request A Quote
			</LoadingButton>
		</Stack>
	);
};

export default RequestAQuote;
