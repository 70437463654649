import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	CardContent,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Skeleton,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { Polygon } from '@react-google-maps/api';
import { upperFirst } from 'lodash';
import { useState } from 'react';
import { AiFillHome } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../apis';
import Map from '../../components/Map/Map';
import Modal from '../../components/Modal/Modal';
import { farmPackages, mapPolygonStyles } from '../../constants';
import { ReactComponent as CloseIcon } from '../../images/Close.svg';
import { formatNumber, getPosition, getSubscriptionStatus, getTimelines } from '../../utils/functions';
import { packages } from '../BPOSub/PremPay';

const useStyles = makeStyles((theme) => ({
	root: {},
	card: {
		padding: '10px',
	},
	title: {
		fontSize: '18px',
		color: theme.secondaryColor,
		textTransform: 'capitalize',
		fontWeight: '500',
	},
	accSum: {
		boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
		borderRadius: '5px',
	},
	gridItem: {
		'&>*': {
			textTransform: 'capitalize',
		},
	},
}));

const replacer = (text) => {
	const replacement = text.metadata.product?.replace(/[-_]/g, ' ');

	if (replacement === 'premier agent website') {
		return `premier agent ${text.metadata.package_grade || 'website'}`;
	}
	if (replacement === 'bpo combo farm') return 'premier agent combo';
	return replacement;
};

const options = ['basic', 'standard', 'plain', 'pro', 'elite', 'ultra'];

function MySubscriptions() {
	const classes = useStyles();
	const [terModal, setTerModal] = useState(false);
	const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
	const [interval, setInterval] = useState('month');

	const [subscriptions, setSubscriptions] = useState([]);
	const [prices, setPrices] = useState(null);
	const [setIsLoading] = useOutletContext();
	const [selectedTerritory] = useState(null);
	const user_id = useSelector((state) => state.auth.id);
	const [invoices, setInvoices] = useState([]);
	const [backupPrices, setBackupPrices] = useState([]);
	const [upgradeOptions, setUpgradeOptions] = useState(null);

	const calcUpgradePricings = (prices) => {
		//prices for prem pay
		const premPay = prices?.filter((item) => item.metadata?.product === 'premier_agent_website' && item.active);
		const basicMonthly = premPay.find((item) => item.metadata?.package_grade === 'basic' && item.recurring?.interval === 'month');
		const standardMonthly = premPay.find((item) => item.metadata?.package_grade === 'standard' && item.recurring?.interval === 'month');
		const basicYearly = premPay.find((item) => item.metadata?.package_grade === 'basic' && item.recurring?.interval === 'year');
		const standardYearly = premPay.find((item) => item.metadata?.package_grade === 'standard' && item.recurring?.interval === 'year');
		//prices for farm products
		const farmProducts = prices?.filter((item) => item.metadata?.product === 'premier_farm_agent' && item.active);
		const plainMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'basic' && item.metadata?.recurring_type === 'monthly');
		const proMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'pro' && item.metadata?.recurring_type === 'monthly');
		const eliteMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'elite' && item.metadata?.recurring_type === 'monthly');
		const ultraMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'ultra' && item.metadata?.recurring_type === 'monthly');
		const plainYearly = farmProducts.find((item) => item.metadata?.package_grade === 'basic' && item.metadata?.recurring_type === 'yearly');
		const proYearly = farmProducts.find((item) => item.metadata?.package_grade === 'pro' && item.metadata?.recurring_type === 'yearly');
		const eliteYearly = farmProducts.find((item) => item.metadata?.package_grade === 'elite' && item.metadata?.recurring_type === 'yearly');
		const ultraYearly = farmProducts.find((item) => item.metadata?.package_grade === 'ultra' && item.metadata?.recurring_type === 'yearly');

		const toSet = {
			basic: {
				pricing: {
					monthly: {
						priceId: basicMonthly?.id,
						amount: formatNumber(basicMonthly?.unit_amount / 100),
					},
					yearly: {
						priceId: basicYearly?.id,
						amount: formatNumber(basicYearly?.unit_amount / 100),
					},
				},
				farm_number: +(basicMonthly?.metadata?.farm || 0),
				...packages[0],
			},
			standard: {
				pricing: {
					monthly: {
						priceId: standardMonthly?.id,
						amount: formatNumber(standardMonthly?.unit_amount / 100),
					},
					yearly: {
						priceId: standardYearly?.id,
						amount: formatNumber(standardYearly?.unit_amount / 100),
					},
				},
				farm_number: +(standardMonthly?.metadata?.farm || 0),

				...packages[1],
			},
			plain: {
				pricing: {
					monthly: {
						priceId: plainMonthly?.id,
						amount: formatNumber(plainMonthly?.unit_amount / 100),
					},
					yearly: {
						priceId: plainYearly?.id,
						amount: formatNumber(plainYearly?.unit_amount / 100),
					},
				},
				farm_number: +(plainMonthly?.metadata?.farm || 0),

				...farmPackages[0],
			},
			pro: {
				pricing: {
					monthly: {
						priceId: proMonthly?.id,
						amount: formatNumber(proMonthly?.unit_amount / 100),
					},
					yearly: {
						priceId: proYearly?.id,
						amount: formatNumber(proYearly?.unit_amount / 100),
					},
				},
				farm_number: +(proMonthly?.metadata?.farm || 0),

				...farmPackages[1],
			},
			elite: {
				pricing: {
					monthly: {
						priceId: eliteMonthly?.id,
						amount: formatNumber(eliteMonthly?.unit_amount / 100),
					},
					yearly: {
						priceId: eliteYearly?.id,
						amount: formatNumber(eliteYearly?.unit_amount / 100),
					},
				},
				farm_number: +(eliteMonthly?.metadata?.farm || 0),

				...farmPackages[2],
			},
			ultra: {
				pricing: {
					monthly: {
						priceId: ultraMonthly?.id,
						amount: formatNumber(ultraMonthly?.unit_amount / 100),
					},
					yearly: {
						priceId: ultraYearly?.id,
						amount: formatNumber(ultraYearly?.unit_amount / 100),
					},
				},
				farm_number: +(ultraMonthly?.metadata?.farm || 0),
				...farmPackages[3],
			},
		};
		setUpgradeOptions(toSet);
	};

	const dispatch = useDispatch();

	useEffect(() => {
		const geter = async () => {
			setIsLoading(true);
			try {
				const { data: invoices } = await base.get(`/register/unpaid_invoices/`);
				const { data: subscriptions } = await base.get(`register/subscriptions/?user_id=${user_id}`);
				const { data: prices } = await base.get(`register/list_prices/`);
				setSubscriptions(subscriptions);
				dispatch({ type: 'SET_SUBSCRIPTONS', payload: getSubscriptionStatus(subscriptions) });
				setInvoices(invoices);
				const flatPrices = prices.flat();
				setBackupPrices(flatPrices);
				calcUpgradePricings(flatPrices);
				const priceMap = {};
				flatPrices.forEach((price) => {
					priceMap[price.id] = price.unit_amount / 100;
					priceMap[`name-${price.id}`] = replacer(price);
				});
				setPrices(priceMap);
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		};
		geter();
	}, [setIsLoading, user_id, dispatch]);

	const navigate = useNavigate();

	const getFarmPrices = () => {
		setIsLoading(true);
		base
			.get('/register/farm_prices/', {
				params: {
					city__iexact: selectedTerritory.city,
				},
			})
			.then(({ data }) => {
				const state = {
					id: selectedTerritory.id,
					name: selectedTerritory?.name,
					city: selectedTerritory?.city,
					county: selectedTerritory?.county,
					isMain: selectedTerritory?.farmType === 'main',
					interval: selectedTerritory?.interval,
					state: selectedTerritory?.state,
					selectedPrice: selectedTerritory?.selectedPrice,
					subscriptionItemId: selectedTerritory?.subscriptionItemId,
					price_data: data[0].price_data,
				};
				navigate('/update-farm', { state });
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				toast.error('Could not get farm prices');
			});
	};

	const [menuOption, setMenuOption] = useState('first');

	const activateInvoice = async (invoice) => {
		setIsLoading(true);
		try {
			const { data } = await base.post('/register/pay_invoice/', {
				invoice_id: invoice.id,
			});
			if (data?.invoce?.id) {
				setInvoices((prev) => prev.filter((inv) => inv.id !== data?.invoice.id));
			}
			toast.success('Invoice paid successfully');
			const { data: invoices } = await base.get(`/register/unpaid_invoices/`);
			setInvoices(invoices);
		} catch (error) {
			toast.error('Could not pay invoice');
		} finally {
			setIsLoading(false);
		}
	};

	const getType = (item, packageName) => {
		if (['basic', 'elite', 'pro', 'ultra', 'standard'].includes(item.package_grade)) {
			return `${upperFirst(item.package_grade)} ${
				item.farm_id ? '' : ['bpo-farm', 'premier_farm_agent'].includes(packageName) ? '(Farm not selected yet)' : ''
			}`;
		}
		if (!item.farm_id) return null;
		const metadata = backupPrices?.find((price) => price.id === item.price_id)?.metadata;
		if (!metadata) return null;
		return metadata?.package_grade;
	};

	const [selectedSub, setSelectedSub] = useState(null);
	const [selectedPrice, setSelectedPrice] = useState(null);
	const [allowed, setAllowed] = useState(0);
	const onStartUpgrade = (sub) => {
		setUpgradeModalOpen(true);
		setSelectedSub(sub);
		setInterval(sub.interval.slice(0, -2));
		setSelectedPrice(null);
		setPriceIndex(null);
		let num = 0;
		const grade = sub.items[0].package_grade;
		const itemPackage = sub.package;
		if (['premier-agent-website', 'premier_agent_website'].includes(itemPackage)) {
			if (grade === 'standard') num = 1;
		}
		if (['bpo-farm', 'bpo_farm'].includes(itemPackage)) {
			if (grade === 'basic') num = 2;
			if (grade === 'pro') num = 3;
			if (grade === 'elite') num = 4;
			if (grade === 'ultra') num = 5;
		}
		setAllowed(num);
	};

	const [receipts, setReceipts] = useState(null);
	const [invoiceLoading, setInvoiceLoading] = useState(false);
	const [priceIndex, setPriceIndex] = useState(null);
	const [terrModal, setTerrModal] = useState(false);
	const [terToRemove, setTerToRemove] = useState([1384]);
	const [allocTerritories, setAllocTerritories] = useState([]);
	const status = priceIndex > allowed ? 'upgrade' : 'downgrade';
	const agentId = useSelector((state) => state.auth.typeId);
	const [numFarmsToRemove, setNumFarmsToRemove] = useState(null);
	const [isTerLoading, setIsTerLoading] = useState(false);

	const startTerSelection = async () => {
		setTerrModal(true);
		setTerToRemove([]);
		setIsTerLoading(true);
		try {
			const { data } = await base.get('/farm/territories/', {
				params: {
					agent_id: agentId,
				},
			});
			if (data.length === 0) setTerrModal(false);
			//if no allocated terr continue
			setAllocTerritories(data);
		} catch (error) {
			toast.error('Could not get allocated territories');
		} finally {
			setIsTerLoading(false);
		}
	};

	const startUpgrade = async () => {
		if (!selectedPrice) {
			toast.warn('Please select a product');
			return;
		}
		try {
			setInvoiceLoading(true);
			const { data } = await base.post('/register/generate-upgrade-downgrade-invoice/', {
				new_price_id: selectedPrice,
				subscription_id: selectedSub.id,
			});
			setReceipts(data);
			setReceiptOpen(true);

			const newPrice = upgradeOptions[options[priceIndex]]?.farm_number;
			const oldPrice = upgradeOptions[options[allowed]]?.farm_number;
			const numFarmsToRemove = oldPrice - newPrice;
			setNumFarmsToRemove(numFarmsToRemove);
			if (status === 'downgrade' && numFarmsToRemove > 0) {
				//let agent select territory
				startTerSelection();
			}
		} catch (error) {
			toast.error(error?.response?.data?.message || 'An error occured');
		} finally {
			setInvoiceLoading(false);
		}
	};

	const [receiptOpen, setReceiptOpen] = useState(false);
	const withoutRemaining = receipts?.slice(0, -1);
	const sumOfWithout = withoutRemaining?.reduce((acc, cur) => cur.amount + acc, 0);

	const [isPayLoading, setIsPayLoading] = useState(false);

	const handlePayNow = async () => {
		try {
			setIsPayLoading(true);
			const payload = {
				subscription_id: selectedSub.id,
				new_price_id: selectedPrice,
				old_price_id: selectedSub?.items[0].price_id,
				status: status,
			};
			if (status === 'downgrade') {
				payload.farm_list = terToRemove;
			} else {
				payload.amount = sumOfWithout;
			}
			const { data } = await base.post('/register/pay-upgrade-downgrade-invoice/', payload);
			const farm_count = data?.farm_count ?? 0;
			setSubscriptions((val) => {
				const index = val.findIndex((el) => el.id === data.subscription.id);
				val[index] = data.subscription;
				return val;
			});
			if (farm_count > 0) {
				toast.success(`Package changed successfully.${farm_count > 0 ? 'Please select additional farm(s)' : ''}`);
				//navigate to available territories
				setTimeout(() => {
					navigate('/available-territories');
				}, 3000);
			} else {
				toast.success(`Package changed successfully.`);
			}
			setUpgradeModalOpen(false);
			setReceiptOpen(false);
		} catch (error) {
			let message = 'An error occured';
			if (error.message.includes('Your card was declined')) {
				message = 'Your card was declined';
			}
			toast.error(message);
		} finally {
			setIsPayLoading(false);
		}
	};

	const onFarmsConfirm = () => {
		if (numFarmsToRemove && terToRemove.length !== numFarmsToRemove) {
			const remainder = numFarmsToRemove - terToRemove.length;
			toast.warn(`Please select ${remainder} more farm${remainder > 1 ? 's' : ''}`);
			return;
		}
		setTerrModal(false);
	};

	return (
		<>
			<Modal open={terModal} onClose={() => setTerModal(false)} style={{ minWidth: '70vw' }}>
				<div className='termod'>
					<div>
						<Map
							center={{
								lng: selectedTerritory?.parcels?.features.map((item) => item.geometry.coordinates[0][0][0][0])[0],
								lat: selectedTerritory?.parcels?.features.map((item) => item.geometry.coordinates[0][0][0][1])[1],
							}}
						>
							{selectedTerritory?.outline && (
								<Polygon paths={selectedTerritory?.outline.map((el) => ({ lng: el[0], lat: el[1] }))} options={mapPolygonStyles} />
							)}
						</Map>
					</div>
					<div>
						<CloseIcon
							onClick={() => setTerModal(false)}
							style={{
								marginLeft: 'auto',
								display: 'block',
								cursor: 'pointer',
							}}
						/>
						<h2 style={{ margin: 0 }}>{selectedTerritory?.name}</h2>
						<p>
							{selectedTerritory?.state} &nbsp; <span className='gr'>&gt;</span> &nbsp; {selectedTerritory?.county} &nbsp;{' '}
							<span className='gr'>&gt;</span> &nbsp; {selectedTerritory?.city}
						</p>
						<div className='terhouse'>
							<div>
								<div className='tericontainer'>
									<AiFillHome size={40} className='tericon' />
								</div>
								<span>Home Owners</span>
							</div>
							<div>
								<span style={{ width: '180px' }}>Number of home owners in this territory</span>
								<p style={{ fontWeight: '700' }}>300</p>
							</div>
						</div>
						<p className='terside'>BPO Farm By BPO Homes</p>
						<ToggleButtonGroup size='small' color='secondary' value={menuOption} exclusive onChange={(e, value) => setMenuOption(value)}>
							<ToggleButton value='first'>Addons</ToggleButton>
							<ToggleButton value='second'>Marketing Timelines </ToggleButton>
							<ToggleButton value='third'>Subscription</ToggleButton>
						</ToggleButtonGroup>
						<div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
							{menuOption === 'first' && (
								<>
									<p style={{ fontWeight: 'bold' }}>{farmPackages[getPosition(selectedTerritory?.grade)]?.title}</p>
									{farmPackages[getPosition(selectedTerritory?.grade)]?.benefits.map((item, index) => (
										<Box key={item} display='flex' gap='10px' alignItems='center'>
											<CheckIcon color='primary' fontSize='14px' />
											<p style={{ fontWeight: '300', margin: 0 }}>{item}</p>
										</Box>
									))}
								</>
							)}
							{menuOption === 'second' && (
								<>
									{selectedTerritory &&
										getTimelines(selectedTerritory?.grade, selectedTerritory?.created_date, selectedTerritory?.updated_date).map((item, index) => (
											<Box key={item} display='flex' gap='10px' alignItems='center' padding='10px'>
												<CheckIcon color='primary' fontSize='14px' />
												<p style={{ fontWeight: '300', margin: 0 }}>{item}</p>
											</Box>
										))}
								</>
							)}
							{menuOption === 'third' && (
								<>
									<p style={{ fontWeight: 'bold' }}>Your Subscription</p>
									{farmPackages.map((item, index) => (
										<Box key={item.title} display='flex' gap='10px' alignItems='center'>
											<CheckIcon color={getPosition(selectedTerritory?.grade) >= index ? 'primary' : 'grey'} fontSize='14px' />
											<p style={{ fontWeight: '400', margin: 0, color: getPosition(selectedTerritory?.grade) >= index ? 'black' : 'grey' }}>
												{item.title}
											</p>
										</Box>
									))}
									<Button
										color='secondary'
										variant='contained'
										sx={{ fontSize: '16px', display: 'block', marginLeft: 'auto', marginTop: '10px' }}
										onClick={getFarmPrices}
									>
										Change Subscription
									</Button>
								</>
							)}
						</div>
					</div>
				</div>
			</Modal>
			{/* Upgrade Options modal */}
			<Dialog open={upgradeModalOpen} onClose={() => setUpgradeModalOpen(false)} fullWidth maxWidth={'lg'}>
				<DialogTitle>Change Premier Agent Package</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						{options?.map((item, index) => {
							const option = upgradeOptions?.[item];
							return (
								<Grid item xs={4} key={option?.title}>
									<PriceCard
										selected={option?.pricing?.[`${interval}ly`].priceId === selectedPrice}
										amount={option?.pricing?.[`${interval}ly`].amount}
										interval={interval}
										benefits={option?.benefits}
										disabled={index === allowed || index === 0}
										title={option?.title}
										onClick={() => {
											if (index !== allowed && index !== 0) {
												setPriceIndex(index);
												setSelectedPrice(option?.pricing?.[`${interval}ly`].priceId);
											}
										}}
									/>
								</Grid>
							);
						})}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant='text' color='error' sx={{ textTransform: 'capitalize' }} onClick={() => setUpgradeModalOpen(false)}>
						Cancel
					</Button>
					<LoadingButton onClick={startUpgrade} sx={{ textTransform: 'capitalize' }} variant='contained' color='secondary' loading={invoiceLoading}>
						Change
					</LoadingButton>
				</DialogActions>
			</Dialog>
			{/* Invoice Preview Modal */}
			<Dialog open={receiptOpen} onClose={() => setReceiptOpen(false)} fullWidth maxWidth='sm'>
				<DialogTitle>Invoice</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={9}>
							<Typography>Description</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography variant='body1' fontWeight={'700'}>
								Amount
							</Typography>
						</Grid>
						{withoutRemaining?.map((item, index) => (
							<>
								<Grid item xs={9}>
									<Typography>{item.description} </Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body1' fontWeight={'700'}>
										$ {formatNumber(item.amount / 100)}
									</Typography>
								</Grid>
							</>
						))}
						<Grid item xs={12}>
							<Divider my={2} sx={{ opacity: 0.4 }} />
						</Grid>
						<Grid item xs={9}>
							<Typography fontWeight={'700'}>Total amount to {status === 'upgrade' ? 'pay' : 'deducted from next payment'}</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography variant='body1' fontWeight={'700'}>
								$ {formatNumber(Math.abs(sumOfWithout) / 100)}
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant='text' color='error' sx={{ textTransform: 'capitalize' }} onClick={() => setReceiptOpen(false)}>
						Cancel
					</Button>
					<LoadingButton variant='contained' color='secondary' sx={{ textTransform: 'capitalize' }} onClick={handlePayNow} loading={isPayLoading}>
						{status === 'upgrade' ? 'Make Payment' : 'Confirm'}
					</LoadingButton>
				</DialogActions>
			</Dialog>

			<Dialog open={terrModal} onClose={() => setTerrModal(false)} fullWidth maxWidth={'md'}>
				<DialogTitle>
					Select {numFarmsToRemove} farm{numFarmsToRemove > 1 ? 's' : ''} to <b style={{ color: 'red', fontWeight: 'bold' }}>REMOVE</b>
				</DialogTitle>
				<DialogContent dividers>
					{isTerLoading ? (
						<Grid container spacing={2}>
							{Array.from(Array(12).keys()).map((item) => (
								<Grid item xs={4}>
									<Skeleton variant='rounded' width={'100%'} height='100px' />
								</Grid>
							))}
						</Grid>
					) : (
						<Grid container spacing={2}>
							{allocTerritories?.map((item, index) => (
								<Grid item xs={4} key={item.id}>
									<Card sx={{ padding: '1rem' }}>
										<Stack direction='row' justifyContent='space-between' alignItems='center'>
											<Stack gap='1rem'>
												<Typography fontWeight={'700'}>{item.name}</Typography>
												<Typography>
													{item.county}, {item.city}
												</Typography>
											</Stack>
											<Checkbox
												size='small'
												color='secondary'
												checked={terToRemove.includes(item.id)}
												onChange={(event) => {
													if (terToRemove.includes(item.id)) {
														setTerToRemove((v) => v.filter((el) => el !== item.id));
													} else {
														if (terToRemove.length === numFarmsToRemove) {
															toast.warn(`You can only select ${numFarmsToRemove} farm${numFarmsToRemove > 1 ? 's' : ''}`);
															return;
														}
														setTerToRemove((v) => [...v, item.id]);
													}
												}}
											/>
										</Stack>
									</Card>
								</Grid>
							))}
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<Button variant='text' color='error' sx={{ textTransform: 'capitalize' }} onClick={() => setTerrModal(false)}>
						Cancel
					</Button>
					<Button sx={{ textTransform: 'capitalize' }} variant='contained' color='secondary' onClick={onFarmsConfirm}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>

			<div className={classes.root}>
				{invoices.length > 0 && (
					<Box sx={{ backgroundColor: '#ff00002b', borderRadius: '5px' }} padding={2} mb={2}>
						<span>You have unpaid invoice(s)</span>
						{invoices.map((invoice) => (
							<Box mb={1} key={invoice.id}>
								<Grid container spacing={2}>
									<Grid item xs={12} sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
										{invoice?.lines?.data?.[0]?.plan?.metadata?.product || invoice?.lines?.data?.[0]?.description}
									</Grid>
									<Grid item xs={4}>
										$ {formatNumber(invoice?.lines?.data?.[0]?.price?.unit_amount / 100, 2)}
									</Grid>
									<Grid item xs={4} sx={{ textTransform: 'capitalize' }}>
										{invoice?.lines?.data?.[0]?.plan?.metadata?.recurring_type || invoice?.lines?.data?.[0]?.plan?.interval + 'ly'}
									</Grid>
									<Grid item xs={4}>
										<Button
											variant='contained'
											color='secondary'
											onClick={() => {
												activateInvoice(invoice);
											}}
										>
											Renew
										</Button>
									</Grid>
								</Grid>
							</Box>
						))}
					</Box>
				)}
				{subscriptions.map((sub, index) => (
					<Accordion elevation={0} key={sub.subscription_id}>
						<AccordionSummary expandIcon={<ExpandMoreIcon sx={{ opacity: sub.items.length > 0 ? 1 : 0 }} />} className={classes.accSum}>
							<Grid container spacing={2}>
								<Grid item xs={4} className={classes.title}>
									{prices?.[`name-${sub.items[0]?.price_id}`] || sub.package?.replace(/[-_]/g, ' ')}
								</Grid>
								<Grid item xs={4} className={classes.header} sx={{ textTransform: 'capitalize' }}>
									{sub.interval}
								</Grid>
								<Grid item xs={4} className={classes.header} sx={{ textTransform: 'capitalize' }}>
									{new Date(sub.date_created).toLocaleDateString('en-US')}
								</Grid>
							</Grid>
						</AccordionSummary>
						{!['premier agent combo', 'combo package'].includes(prices?.[`name-${sub.items[0]?.price_id}`]) && sub.items.length > 0 && (
							<AccordionDetails>
								{sub.items.map((item) => (
									<Box key={item.id}>
										<Grid container spacing={2} sx={{ padding: '10px', marginBottom: '5px' }} className={classes.gridItem}>
											<Grid item xs={4}>
												{getType(item) || 'Price'}
											</Grid>
											<Grid item xs={3}>
												$ {formatNumber(prices?.[item.price_id] || 0, 2)}
											</Grid>
											{
												<Grid item xs={5}>
													{
														<Button
															sx={{ textTransform: 'capitalize', marginLeft: 'auto', display: 'block' }}
															color='secondary'
															variant='contained'
															onClick={() => onStartUpgrade(sub)}
														>
															Change Subscription
														</Button>
													}
												</Grid>
											}
										</Grid>
									</Box>
								))}
							</AccordionDetails>
						)}
					</Accordion>
				))}
			</div>
		</>
	);
}

export default MySubscriptions;

const PriceCard = ({ selected, amount, interval, benefits, title, onClick, disabled }) => {
	return (
		<Card
			sx={{
				maxWidth: '100%',
				border: `1px solid ${selected ? '#005EA9' : 'white'}`,
				cursor: 'pointer',
				filter: `grayscale(${disabled ? 1 : 0})`,
				padding: '1rem',
			}}
			onClick={onClick}
		>
			<Typography color='#005EA9' fontWeight='bold' textAlign={'center'} fontSize={'20px'} sx={{ textTransform: 'uppercase' }}>
				{title?.replace(' (SET UP FEE NOT INCLUDED $99)', '')}
			</Typography>
			<Divider
				sx={(theme) => ({
					background: theme.secondaryColor,
					height: '2px',
					width: '5rem',
					margin: '10px auto ',
					display: 'block',
					border: 'none !important',
				})}
			/>
			<Typography display='flex' gap='10px' mx='auto' justifyContent={'center'}>
				<Typography variant='span' sx={{ fontWeight: 'bolder', fontSize: '20px' }}>
					$ {amount}
				</Typography>
				per {interval === 'month' ? 'month' : 'year'}
			</Typography>
			<CardContent sx={{ height: '150px', overflowY: 'auto', borderTop: '1px solid #ededed', padding: '0.5rem !important' }}>
				<List>
					{benefits.map((item, index) => (
						<ListItem disableGutters disablePadding key={index}>
							<ListItemText>
								<p style={{ fontWeight: '300', fontSize: '14px', margin: 0 }} dangerouslySetInnerHTML={{ __html: item }}></p>
							</ListItemText>
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
	);
};
