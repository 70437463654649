import { Box, Button, Container, Grid, Skeleton, Stack, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { Selector } from './TemplateScreen/TemplateScreen';
import IndThumb1 from '../images/indthumb1.jpg';
import IndThumb2 from '../images/indthumb2.jpg';
import IndThumb3 from '../images/indthumb3.jpg';
import IndThumb4 from '../images/indthumb4.jpg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import base from '../apis';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner/Spinner';

const templates = [
	{
		title: 'Construction Templates',
		options: [
			{
				title: 'construction_1',
				image: IndThumb1,
				id: 1,
			},
		],
	},
	{
		title: 'Landscaping Templates',
		options: [
			{
				title: 'land_1',
				image: IndThumb2,
				id: 2,
			},
		],
	},
	{
		title: 'Electrical Templates',
		options: [
			{
				title: 'electrical_1',
				image: IndThumb3,
				id: 2,
			},
		],
	},
	{
		title: 'Plumbing Templates',
		options: [
			{
				title: 'plumbing_1',
				image: IndThumb4,
				id: 2,
			},
		],
	},
];

const IndProfWebHomePage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const { indElite } = useSelector((state) => state.subscriptions);
	const typeId = useSelector((state) => state.auth.typeId);
	const navigate = useNavigate();

	useEffect(() => {
		if (!indElite) {
			navigate('industry-prof-subscription');
		}

		(async () => {
			try {
				setIsLoading(true);
				const { data } = await base.get('register/ind-prof/profile/');
				setSelectedTemplate(data?.prof_template);
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		})();
	}, [indElite, navigate]);

	const [isChangeLoading, setIsChangeLoading] = useState(false);
	const handleChangeTemplate = async (id) => {
		try {
			setIsChangeLoading(true);
			const { data } = await base.patch('register/ind-prof/profile/', {
				prof_template: id,
			});
			setSelectedTemplate(data.prof_template);
		} catch (error) {
			toast.error('Could not update template');
		} finally {
			setIsChangeLoading(false);
		}
	};

	if (isLoading) {
		return (
			<Container>
				<Skeleton variant='text' height={40} width={300} sx={{ marginBottom: 4 }} />

				{[1, 2, 3].map((section, index) => (
					<Box key={index} sx={{ marginBottom: 4 }}>
						<Skeleton variant='text' height={30} width={250} sx={{ marginBottom: 2 }} />
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Skeleton variant='rectangular' height={200} />
							</Grid>
						</Grid>
					</Box>
				))}
			</Container>
		);
	}
	return (
		<>
			{isChangeLoading && <Spinner />}
			<Stack sx={{ padding: '1rem', minHeight: '100vh' }} gap='1rem'>
				<Stack direction='row' justifyContent={'space-between'}>
					<Typography fontSize={'20px'} fontWeight={700}>
						Select your Template
					</Typography>
					<Button
						sx={{ textTransform: 'capitalize' }}
						variant='outlined'
						color='secondary'
						href={`/industry-professional-website/${typeId}`}
						target='_blank'
					>
						Preview
					</Button>
				</Stack>
				{templates.map((item) => (
					<Fragment key={item.title}>
						<Typography fontWeight={700} fontSize={20} mt='2rem'>
							{item.title}
						</Typography>
						<Grid container spacing={2}>
							{item.options.map((el, index) => (
								<Grid item xs={3} key={el.id}>
									<Selector
										image={el.image}
										number={index + 1}
										isSelected={el.title === selectedTemplate}
										onSetNewTemplate={() => handleChangeTemplate(el.title)}
									></Selector>
								</Grid>
							))}
						</Grid>
					</Fragment>
				))}
			</Stack>
		</>
	);
};

export default IndProfWebHomePage;
