import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { URL } from '../constants';
import { getSubscriptionStatus } from '../utils/functions';
import { Grid, Skeleton } from '@mui/material';
import IndProfTemp1 from './IndProfTemp1';
import IndProfTemp2 from './IndProfTemp2';
import IndProfTemp3 from './IndProfTemp3';
import IndProfTemp4 from './IndProfTemp4';

const IndProfTemplate = () => {
	//get data
	const { id } = useParams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [userDetails, setUserDetails] = useState(null);

	const Temps = {
		construction_1: IndProfTemp1,
		land_1: IndProfTemp2,
		electrical_1: IndProfTemp3,
		plumbing_1: IndProfTemp4,
	};

	useEffect(() => {
		(async () => {
			try {
				//get profile details
				setIsLoading(true);
				const { data } = await axios.get(URL + `professionals/${id}/`);

				setUserDetails(data);
				//check subscription
				const subscriptionsRes = await axios.get(URL + `register/subscriptions/`, { params: { user_id: data.user.id } });
				const { indElite } = getSubscriptionStatus(subscriptionsRes.data);
				if (!indElite) {
					navigate('/404', { replace: true });
					return;
				}
			} catch (error) {
				navigate('/404', { replace: true });
			} finally {
				setIsLoading(false);
			}
		})();
	}, [id, navigate]);

	if (isLoading) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Skeleton width='100%' height='50vh' variant='rounded' />
				</Grid>
				<Grid item xs={9}>
					<Skeleton width='100%' height='50vh' variant='rounded' />
				</Grid>
				<Grid item xs={3}>
					<Skeleton width='100%' height='50vh' variant='rounded' />
				</Grid>
			</Grid>
		);
	}
	const Component = Temps[userDetails?.prof_template || 'construction_1'];

	return <Component {...userDetails} />;
};

export default IndProfTemplate;
