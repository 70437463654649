import { LoadingButton as LBut } from '@mui/lab';
import { Grid, Button as MButt, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../apis';
import { ReactComponent as Stars } from '../images/starsInd.svg';
import { useSelector } from 'react-redux';
import { CheckCircle } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
		padding: '1rem',
		fontFamily: 'Inter,san-serif',
	},
	card: {
		boxShadow: '0px 1px 2px 0px #1018280D',
		border: '1px solid #EAECF0',
		padding: '1.25rem 1rem',
		borderRadius: '12px',
		height: '100%',
	},
	text: {
		fontFamily: 'Inter',
		fontWeight: 600,
		color: '#101828',
	},
	tableHeader: {
		width: 'unset !important',
		padding: '10px',
		color: '#475467',
		fontSize: '12px',
		fontWeight: 700,
		fontFamily: 'Inter',
	},
	tablecell: {
		width: 'unset !important',
		padding: '10px',
		fontWeight: 400,
		fontFamily: 'Inter',
		fontSize: '14px',
		color: '#475467',
	},
	upgradeCard: {
		boxShadow: '0px 1px 2px 0px #1018280D',
		border: '1px solid #EAECF0',
		// padding: '1.25rem 1rem',
		borderRadius: '12px',
		height: '100%',
	},
	table: {
		'& tbody tr:last-child td': {
			border: '0 !important',
		},
	},
}));

const Button = styled(MButt)(({ theme }) => ({
	textTransform: 'capitalize',
	fontSize: '14px',
	fontFamily: 'Inter',
	fontWeight: 600,
	flex: 1,
	borderRadius: '8px',
}));
const LoadingButton = styled(LBut)(({ theme }) => ({
	textTransform: 'capitalize',
	fontSize: '14px',
	fontFamily: 'Inter',
	fontWeight: 600,
	flex: 1,
	borderRadius: '8px',
}));

const IndustryDashboard = () => {
	const classes = useStyles();
	const typeId = useSelector((state) => state.auth.typeId);
	const { indPro, indElite, indUltra } = useSelector((state) => state.subscriptions);

	return (
		<Stack className={classes.root} gap='3rem'>
			<Stack sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '20px' }} direction='row' justifyContent={'space-between'}>
				<Typography variant='h1' color='#475467' fontWeight={700} fontSize='24px'>
					My Dashboard
				</Typography>
				<Button href={`/industry-professionals/${typeId}`} target='_blank' variant='contained' color='secondary' sx={{ flex: 'unset !important' }}>
					View Public Profile
				</Button>
			</Stack>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<TotalQuotes />
				</Grid>
				<Grid item xs={6}>
					<TotalQuotes newQuote={false} />
				</Grid>
				<Grid item xs={8}>
					<iframe
						style={{ width: '100%', height: '100%', border: 'none', borderRadius: '8px' }}
						src='https://www.youtube.com/embed/_ZbR-Gp9vEg?si=h8Nu44hYPiKLVHqI'
						title='YouTube video player'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						referrerPolicy='strict-origin-when-cross-origin'
						allowFullScreen
					></iframe>
				</Grid>
				<Grid item xs={4}>
					<Stack gap='1rem'>
						<Upgrader title='Pro' price='29.00' check={indPro && !indElite && !indUltra}>
							<Typography className={classes.text} color='#475467 !important' fontWeight={'400 !important'}>
								Become a <b style={{ fontWeight: 700 }}>Featured Business</b> and Link your Website
							</Typography>
						</Upgrader>
						<Upgrader title='Elite' price='49.00' check={indElite && !indUltra}>
							<Typography className={classes.text} color='#475467 !important' fontWeight={'400 !important'}>
								Become a <b style={{ fontWeight: 700 }}>Featured Business</b> and get a <b>Free Website</b>
							</Typography>
						</Upgrader>
						<Upgrader title='Ultra' price='149.00' check={indUltra}>
							<Typography className={classes.text} color='#475467 !important' fontWeight={'400 !important'}>
								Become a <b style={{ fontWeight: 700 }}>Featured Business</b>, get a <b>Free Website</b> with <b>Live Virtual Assitant</b>
							</Typography>
						</Upgrader>
					</Stack>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs={6}>
					<Card>
						<Tabler />
					</Card>
				</Grid>
				<Grid item xs={6}>
					<Card>
						<Tabler newQuote={false} />
					</Card>
				</Grid>
			</Grid>

			<Stack gap='24px'>
				<Stack id='more' gap='6px'>
					<Typography className={classes.text} color='#0D5894 !important'>
						Upgrade
					</Typography>
					<Typography className={classes.text} color='#101828 !important' fontSize='18px !important'>
						Unlock more features
					</Typography>
					<Typography className={classes.text} color='#475467 !important' fontSize='14px !important' fontWeight={'400 !important'}>
						Get more features to increase your reach and productivity{' '}
					</Typography>
				</Stack>

				{/* ##upgrade */}
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<UpgradeCard />
					</Grid>
					<Grid item xs={4}>
						<UpgradeCard
							title='Elite'
							price='49.00'
							others={['Website Stripe Payment Processing Included', 'Assist with Google Business Page Set Up']}
						>
							<li>
								Professional Business Website with Hosting Included <br />
								<i>($12.00 Yearly Domain Purchase Business with GoDaddy Not Included)</i>
							</li>
						</UpgradeCard>
					</Grid>
					<Grid item xs={4}>
						<UpgradeCard
							title='Ultra'
							price='149.00'
							others={[
								'Website Stripe Payment Processing Included',
								'Assist with Google Business Page Set Up',
								'Dedicated Local Business Line',
								'Live Virtual Assistant 8am-5pm Client Business Hours Only',
							]}
						>
							<li>
								Professional Business Website with Hosting Included <br />
								<i>($12.00 Yearly Domain Purchase Business with GoDaddy Not Included)</i>
							</li>
						</UpgradeCard>
					</Grid>
				</Grid>
			</Stack>
		</Stack>
	);
};

export default IndustryDashboard;

const Card = ({ children }) => {
	const classes = useStyles();
	return (
		<Stack className={classes.card} gap='1rem'>
			{children}
		</Stack>
	);
};

const Tabler = ({ newQuote = true }) => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([]);
	useEffect(() => {
		(async () => {
			try {
				const url = newQuote ? 'professionals/quotes' : 'professionals/follow-up/';
				const params = {};
				params[`${newQuote ? 'is_viewed' : 'is_sent'}`] = false;
				const { data } = await base.get(url, {
					params,
				});

				const transformedData = newQuote
					? data?.results.map((i) => ({ name: `${i.first_name} ${i.last_name}`, email: i.email, date: i.created_at, id: i.id }))
					: data?.results.map((i) => ({
							name: `${i.request_quote?.first_name} ${i.request_quote?.last_name}`,
							email: i.request_quote?.email,
							date: i.send_date,
							id: i.id,
					  }));
				setData(transformedData);
			} catch {
			} finally {
				setIsLoading(false);
			}
		})();
	}, [newQuote]);

	const navigate = useNavigate();
	const [isNowSending, setIsNowSending] = useState(false);
	const handleClick = async (id) => {
		if (newQuote) {
			navigate(`/requested-quotes/${id}`);
		} else {
			try {
				setIsNowSending(true);
				await base.post('professionals/send-follow-up/', {
					id,
				});
				setData((v) => v.filter((i) => i.id !== id));
			} catch (error) {
				toast.warn('Could not send follow up now');
			} finally {
				setIsNowSending(false);
			}
		}
	};

	if (isLoading)
		return (
			<>
				<Stack justifyContent={'space-between'} direction='row'>
					<Skeleton variant='text' width={250} height={30} />
					<Skeleton variant='text' width={150} height={30} />
				</Stack>

				{/* Skeleton for the table headers */}
				<Grid container spacing={2} style={{ marginBottom: 16 }}>
					<Grid item xs={3}>
						<Skeleton variant='text' width='100%' height={30} />
					</Grid>
					<Grid item xs={5}>
						<Skeleton variant='text' width='100%' height={30} />
					</Grid>
					<Grid item xs={2}>
						<Skeleton variant='text' width='100%' height={30} />
					</Grid>
					<Grid item xs={2}>
						<Skeleton variant='text' width='100%' height={30} />
					</Grid>
				</Grid>

				{/* Skeleton for table rows */}
				{Array.from(new Array(3)).map((_, index) => (
					<Grid container spacing={2} key={index} style={{ marginBottom: 16 }}>
						<Grid item xs={3}>
							<Skeleton variant='text' width='100%' height={30} />
						</Grid>
						<Grid item xs={5}>
							<Skeleton variant='text' width='100%' height={30} />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant='text' width='100%' height={30} />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant='text' width='100%' height={30} />
						</Grid>
					</Grid>
				))}
			</>
		);

	return (
		<>
			<Stack direction='row' justifyContent='space-between'>
				<Typography fontFamily={'Inter'} color='#005EA9' fontWeight={700} fontSize={'18px'}>
					{newQuote ? 'New Quote Requests' : 'Pending Follow-ups'}
				</Typography>
				<Button
					variant='outlined'
					color='secondary'
					sx={{ borderColor: '#D0D5DD', color: '#344054', flex: 'unset !important' }}
					href={newQuote ? 'requested-quotes' : 'follow-ups'}
				>
					Show All {newQuote ? 'Requests' : 'Follow-ups'}
				</Button>
			</Stack>
			<div style={{ border: '1px solid #EAECF0', borderRadius: '12px', overflow: 'hidden' }}>
				<Table className={classes.table}>
					<TableHead sx={{ background: '#F9FAFB' }}>
						<TableRow>
							<TableCell className={classes.tableHeader}>Name</TableCell>
							<TableCell className={classes.tableHeader}>Email</TableCell>
							<TableCell className={classes.tableHeader}>{newQuote ? 'Request' : 'Follow-up'} Date</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((el) => (
							<TableRow key={el.id}>
								<TableCell
									sx={{ width: 'unset !important', padding: '10px', fontWeight: 400, fontFamily: 'Inter', fontSize: '14px', color: '#475467' }}
								>
									{el.name}
								</TableCell>
								<TableCell
									sx={{ width: 'unset !important', padding: '10px', fontWeight: 400, fontFamily: 'Inter', fontSize: '14px', color: '#475467' }}
								>
									{el.email}
								</TableCell>
								<TableCell
									sx={{ width: 'unset !important', padding: '10px', fontWeight: 400, fontFamily: 'Inter', fontSize: '14px', color: '#475467' }}
								>
									{dayjs(el.date).format('MMMM D, YYYY')}
								</TableCell>
								<TableCell
									sx={{
										width: 'unset !important',
										padding: '10px',
										fontWeight: 400,
										fontFamily: 'Inter',
										fontSize: '14px',
										color: '#0D5894',
									}}
								>
									<LoadingButton
										variant='text'
										color='secondary'
										sx={{ whiteSpace: 'nowrap' }}
										onClick={() => handleClick(el.id)}
										loading={isNowSending}
									>
										{newQuote ? 'Generate Quote' : 'Follow-up Now'}
									</LoadingButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		</>
	);
};

const UpgradeCard = ({ title = 'Pro', price = '29.00', children, others = [] }) => {
	const classes = useStyles();
	return (
		<Stack className={classes.upgradeCard} justifyContent='space-between'>
			<Stack>
				<Stack direction='row' gap='1rem' alignItems={'center'} padding='1rem' borderBottom='1px solid #EAECF0'>
					<Stars />
					<Typography sx={{ color: '#344054', fontFamily: 'Inter', fontWeight: 700 }}>Industry Professional {title}</Typography>
				</Stack>
				<Stack padding='1rem'>
					<Stack direction='row' alignItems='center' gap='10px'>
						<Typography className={classes.text} fontSize={30} color='#344054 !important'>
							${price}
						</Typography>
						<Typography
							className={classes.text}
							color='#475467 !important'
							fontWeight={'400 !important'}
							fontSize='14px !important'
							fontFamily='Inter !important'
						>
							per month
						</Typography>
					</Stack>
					<ul style={{ paddingLeft: '2rem', color: '#475467', fontFamily: 'Inter ', lineHeight: 2 }}>
						<li>
							Business Listed as Featured Business on{' '}
							<a style={{ color: '#0D5894', cursor: 'pointer', fontStyle: 'italic' }} target='_blank' rel='noreferrer' href='https://bpohomes.com'>
								BPOHomes.com
							</a>
						</li>
						{children}
						<li>Web Hosting Included</li>
						<li>Quote Generator</li>
						{others.map((el) => (
							<li key={el}>{el}</li>
						))}
					</ul>
					<Typography className={classes.text} fontWeight={'400 !important'} color='#475467 !important' fontFamily='Inter !important'>
						<b>Please Note:</b> There is a $99.00 One-Time Set-Up Fee
					</Typography>
				</Stack>
			</Stack>
			<Stack p='1rem'>
				<Button variant='contained' color='secondary' disableElevation href='/industry-prof-subscription'>
					Upgrade Now
				</Button>
			</Stack>
		</Stack>
	);
};

const TotalQuotes = ({ newQuote = true }) => {
	const classes = useStyles();
	const [count, setCount] = useState(0);
	useEffect(() => {
		(async () => {
			const url = 'professionals/quotes';
			const { data } = await base.get(url, {
				params: {
					is_viewed: !newQuote,
				},
			});
			setCount(data.count);
		})();
	}, [newQuote]);
	return (
		<Card>
			<Typography className={classes.text}>{newQuote ? 'Total Quotes Request' : 'Total Quotes Generated'}</Typography>
			<Typography className={classes.text} fontSize={30}>
				{count}
			</Typography>
		</Card>
	);
};

const Upgrader = ({ title, price, children, check }) => {
	const classes = useStyles();
	return (
		<Stack gap='1rem'>
			<Stack
				gap='1rem'
				sx={{
					boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
					border: '1px solid #EAECF0',
					backgroundColor: '#FFFFFF',
					padding: '1rem',
					borderRadius: '1rem',
				}}
			>
				<Stack direction='row' justifyContent={'space-between'} alignItems='center'>
					<Typography className={classes.text} color='#475467 !important' fontSize={18}>
						Industry Professional {title}
					</Typography>
					{check && <CheckCircle sx={{ fill: 'green' }} />}
				</Stack>
				<Stack direction='row' alignItems='center' gap='10px'>
					<Typography className={classes.text} fontSize={30}>
						${price}
					</Typography>
					<Typography className={classes.text} color='#475467'>
						per month
					</Typography>
				</Stack>
				{children}
				<Stack direction='row' gap='1rem'>
					<Button variant='contained' color='secondary' disableElevation href='/industry-prof-subscription'>
						Upgrade Now
					</Button>
					<Button variant='outlined' color='secondary' sx={{ borderColor: '#D0D5DD', color: '#344054' }} href='#more'>
						Learn More
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};
