import { ExpandMore, MoreVert } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Button as NButton,
	Pagination,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../apis';
import { useSelector } from 'react-redux';

const Button = styled(NButton)(({ theme, isactive }) => ({
	textTransform: 'capitalize',
	backgroundColor: isactive ? theme.secondaryColor : '#EAF6FF',
	color: isactive ? 'white' : '#5292C5',
	fontFamily: 'Inter',
	fontSize: '16px',
	fontWeight: 500,
	'&:hover': {
		backgroundColor: isactive ? theme.secondaryColor : '#EAF6FF',
		color: isactive ? 'white' : '#5292C5',
	},
}));

Button.defaultProps = {
	disableElevation: true,
	disableFocusRipple: true,
	disableRipple: true,
	disableTouchRipple: true,
};

const useStyles = makeStyles((theme) => ({
	accSum: {
		boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
		borderRadius: '5px',
	},
	header: {
		color: theme.secondaryColor,
		fontSize: '18px',
	},
}));

const RequestedQuotes = () => {
	const [quotes, setQuotes] = useState([]);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [isClosed, setIsClosed] = useState(false);
	const { indPro } = useSelector((state) => state.subscriptions);
	const navigate = useNavigate();

	useEffect(() => {
		if (!indPro) {
			navigate('/industry-prof-subscription');
		}
	}, [indPro, navigate]);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const { data } = await base.get('professionals/quotes', {
					params: {
						is_viewed: isClosed,
					},
				});
				setCount(data.count);
				setPage(0);
				setQuotes(data.results);
			} catch (error) {
				toast.warn(error?.response?.data?.detail || 'Could not get requested quotes');
			} finally {
				setIsLoading(false);
			}
		})();
	}, [isClosed]);

	const handlePageChange = async (e, page) => {
		setPage(page);
		try {
			const { data } = await base.get('professionals/quotes', {
				params: { page: page + 1 },
			});
			setCount(data.count);
			setQuotes(data.results);
		} catch (error) {
			toast.warn('Could not get requested quotes');
		} finally {
			setIsLoading(false);
		}
	};

	const onCloseQuote = async (id) => {
		//remove from quotes and update
		const quote = quotes.find((i) => i.id === id);
		const quotePos = quotes.findIndex((i) => i.id === id);
		setQuotes((v) => v.filter((i) => i.id !== id));
		try {
			await base.patch(`professionals/quotes/${id}/`, {
				is_viewed: true,
			});
		} catch (error) {
			toast.error('Could not close quote');
			setQuotes((v) => v.splice(quotePos, 0, quote));
		}
	};

	return (
		<>
			<Stack p='1rem' sx={{ minHeight: '100vh' }} gap='1rem'>
				<Stack direction='row' gap='1rem'>
					<Button variant='contained' color='secondary' isactive={!isClosed} onClick={() => setIsClosed(false)}>
						Pending Submitted
					</Button>
					<Button variant='contained' color='secondary' isactive={isClosed} onClick={() => setIsClosed(true)}>
						Closed
					</Button>
				</Stack>
				{isLoading ? (
					<TableSkeleton />
				) : (
					<Stack gap='1rem' alignItems='center'>
						<Stack sx={{ width: '100%' }}>
							{quotes.map((item) => (
								<ModAccordion key={item.id} {...item} handleQuoteClose={() => onCloseQuote(item.id)} />
							))}
						</Stack>
						<Pagination page={page} count={Math.ceil(count / 12)} onChange={handlePageChange} color='secondary' variant='outlined' shape='rounded' />
					</Stack>
				)}
			</Stack>
		</>
	);
};

export default RequestedQuotes;

const TableSkeleton = () => {
	return (
		<Stack gap='3px'>
			{Array.from(Array(10).keys()).map((_) => (
				<Skeleton variant='rectangular' width='100%' height={100} key={_} />
			))}
		</Stack>
	);
};

const ModAccordion = ({ first_name, last_name, email, phone_number, message, is_viewed, handleQuoteClose, id }) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const navigate = useNavigate();
	return (
		<Accordion elevation={0}>
			<AccordionSummary expandIcon={<ExpandMore />} className={classes.accSum}>
				<Grid container spacing={2}>
					<Grid item xs={4} className={classes.header}>
						Name
					</Grid>
					<Grid item xs={4} className={classes.header}>
						Email
					</Grid>
					<Grid item xs={4} className={classes.header}>
						Phone Number
					</Grid>
					<Grid item xs={4}>
						{first_name} {last_name}
					</Grid>
					<Grid item xs={4}>
						{email}
					</Grid>
					<Grid item xs={4}>
						{phone_number}
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<Stack direction='row' gap='1rem' alignItems='flex-start'>
					<Typography flex={'1'}>{message}</Typography>
					<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
						<MenuItem href={`/requested-quotes/${id}`} onClick={() => navigate(`/requested-quotes/${id}`)}>
							Generate Quote
						</MenuItem>
						{!is_viewed && <MenuItem onClick={handleQuoteClose}>Close Quote</MenuItem>}
						{/* <MenuItem>Edit Follow up Date</MenuItem> */}
					</Menu>
					<IconButton onClick={handleClick}>
						<MoreVert />
					</IconButton>
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};
