import commaSeparate from '../components/DealAnalyzerComponents/utils/commaSeparate';
import { schedule } from '../constants';
import hull from 'hull.js';

/**
 *
 * @param {Ref} element a ref of the element to scroll to the top off
 */
const scrollToTop = (element) => {
	element.current.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});
};

/**
 *
 * @param {number} days the number days to calculate for after today (including today)
 * @returns an array with key display in the form (Mar 30, Monday) and iso date of as value
 */
const getDatesFromToday = (days = 5, weekday = 'long') => {
	const dates = [];
	for (let i = 0; i < days; i++) {
		dates.push({
			display: new Date(new Date().setDate(new Date().getDate() + i))
				.toLocaleDateString('en', {
					month: 'short',
					weekday,
					day: 'numeric',
				})
				.replace(',', '')
				.split(' '),
			value: new Date().setDate(new Date().getDate() + i),
		});
	}

	return dates;
};

//get name from string with underscore and capitalize first letter
const removeUnderandCapitalize = (string) => {
	return string
		.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

/**
 *
 * @param {*} number number to format
 * @param {*} decimals number of decimals to show, default 2
 * @returns formatted number
 */
const formatNumber = (number, decimals = 2) => Number(number).toLocaleString('en-US', { maximumFractionDigits: decimals });

/**
 *
 * @param {*} url non formatted url
 * @returns url with embedd
 */
function getYoutubeEmbedLink(url) {
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
	const match = url.match(regExp);

	return match && match[2].length === 11 ? match[2] : null;
}

/**
 *
 * @param {[]} subs
 * @returns
 */

const getSubscriptionStatus = (subs) => {
	const status = {
		bpoFarm: false,
		premierAgentWebsite: false,
		liveLeads: false,
		dealAnalyzer: false,
		indPro: false,
		indElite: false,
		indUltra: false,
	};

	if (subs.length === 0) {
		return status;
	}
	if (subs.some((item) => item.package === 'bpo-farm')) {
		return {
			bpoFarm: true,
			premierAgentWebsite: true,
			liveLeads: true,
			dealAnalyzer: true,
			indPro: false,
			indElite: false,
			indUltra: false,
		};
	}
	if (subs.some((item) => item.package === 'combo-package')) {
		status.dealAnalyzer = true;
		status.premierAgentWebsite = true;
		status.liveLeads = true;
	}
	if (subs.some((item) => item.package === 'premier-agent-website')) {
		status.premierAgentWebsite = true;
		status.dealAnalyzer = true;
		status.liveLeads = true;
	}
	if (subs.some((item) => item.package === 'deal-analyzer')) {
		status.dealAnalyzer = true;
	}
	if (subs.some((item) => item.package === 'bpo-realty-agent')) {
		return {
			bpoFarm: true,
			premierAgentWebsite: true,
			liveLeads: true,
			dealAnalyzer: true,
			indPro: false,
			indElite: false,
			indUltra: false,
		};
	}
	if (subs.some((item) => item.package === 'industry_professional_ultra')) {
		return {
			indPro: true,
			indElite: true,
			indUltra: true,
		};
	}
	if (subs.some((item) => item.package === 'industry_professional_elite')) {
		return {
			indPro: false,
			indElite: true,
			indUltra: true,
		};
	}
	if (subs.some((item) => item.package === 'industry_professional_pro')) {
		return {
			indPro: true,
			indElite: false,
			indUltra: false,
		};
	}

	return status;
};

/**
 *
 * @param {string} url URL to check
 * @returns a url with https:// if it doesn't have it
 */
const getValidUrl = (url) => {
	if (!url) {
		return '';
	}
	if (url.includes('://')) return url;
	else return 'https://' + url;
};

/**
 *
 * @param {string} phoneNumberString number to return in the format +1 (123) 456-7890
 * @returns
 */
function formatPhoneNumber(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		// var intlCode = match[1] ? '+1 ' : '';
		return ['(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return cleaned || null;
}

function convertCurrencySystem(labelValue) {
	// Nine Zeroes for Billions
	return Math.abs(Number(labelValue)) >= 1.0e9
		? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
		: // Six Zeroes for Millions
		Math.abs(Number(labelValue)) >= 1.0e6
		? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
		: // Three Zeroes for Thousands
		Math.abs(Number(labelValue)) >= 1.0e3
		? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
		: Math.abs(Number(labelValue));
}

function getAllDaysInMonth(year, month) {
	const today = new Date().getDate();
	const date = new Date(year, month, today);

	const dates = [];

	while (date.getMonth() === month) {
		dates.push(new Date(date).toDateString());
		date.setDate(date.getDate() + 1);
	}

	return dates;
}

/**
 *
 * @param {string} value get type of farm subscription
 * @returns position of farm subscription 0-starter, 1-pro, 2-elite
 */
const getPosition = (value) => {
	switch (value) {
		case 'ultra':
			return 3;
		case 'elite':
			return 2;
		case 'pro':
			return 1;
		default:
			return 0;
	}
};

//add days to date
const addDays = (date, days = 1) => {
	let result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
};
const checkDate = (date, days) => {
	const today = new Date();
	let compDate = date;
	let out = compDate;
	do {
		compDate = addDays(compDate, days);
		out = compDate;
	} while (new Date(today.setHours(0, 0, 0, 0)).getTime() >= new Date(compDate.setHours(0, 0, 0, 0)).getTime());
	return out;
};

const formatDate = (date) => date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

/**
 *
 * @param {string} type type of subscription
 * @param {Date} createdDate created date of subscription
 * @param {Date} updatedDate updated date of subscription
 * @returns Array of timelines
 */
const getTimelines = (type, createdDate, updatedDate) => {
	return schedule.map((item) => {
		let start = formatDate(addDays(updatedDate, item[`${type}_days_to_add`] - 3));
		let end = formatDate(addDays(updatedDate, item[`${type}_days_to_add`]));
		if (['Seller Calling', 'Post Card Mailers'].includes(item.name)) {
			start = formatDate(checkDate(addDays(createdDate, -3), item[`${type}_days_to_add`]));
			end = formatDate(checkDate(createdDate, item[`${type}_days_to_add`]));
		}

		return `Scheduled ${item.name} ${start} - ${end}`;
	});
};

const commaReplace = (value) => {
	const valueString = value.toString().replace(/[^0-9.-]/g, '');
	return commaSeparate(valueString);
};

const dataURLtoFile = (dataurl, filename) => {
	var arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};

/**
 *
 * @param {{parcels:{features}}} territory a territory object with parcels.features
 * @returns the flattened coordinates of the territory outline
 */
const getTerritoriesOutline = (territory) => {
	const coordinates = territory?.parcels?.features?.map((item) => item?.geometry?.coordinates[0][0].map((el) => [el[0], el[1]]))?.flat();
	return hull(coordinates, 20);
};

/**
 *
 * @param {Number} value agent amount to check
 * @returns agent tier number
 */
const getTier = (value) => String(value <= 500 ? 1 : value <= 700 ? 2 : 3);

/**
 *
 * @param {string} id id of element to scroll
 * @param {Boolean} left scroll to the left or right
 */
const scrollToTheLeft = (id, left) => {
	const element = document.getElementById(id);
	//get width of element
	const width = element.offsetWidth / 2;
	//scroll by half
	if (left) {
		element.scrollLeft -= width / 2;
	} else {
		element.scrollLeft += width / 2;
	}
};

/**
 *
 * @param {Ref} ref ref of element to check
 * @param {*} id id of element to check for overflow
 */
const hideButton = (reference, id) => {
	const div = document.getElementById(id);
	const isWidthOverflowing = div.scrollWidth > div.clientWidth;
	if (reference && !isWidthOverflowing) {
		reference.style.display = 'none';
	}
};

/**
 *
 * @param {number} number number to shorten
 * @returns shortened number (1.2M, 1.2K, 1.2B)
 */
const getPriceInShort = (number) => {
	const formatter = new Intl.NumberFormat('en-US', { notation: 'compact' });
	return formatter.format(number);
};

function validateEmail(email) {
	var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
	return regex.test(email);
}

export {
	scrollToTop,
	getDatesFromToday,
	removeUnderandCapitalize,
	formatNumber,
	getYoutubeEmbedLink,
	getSubscriptionStatus,
	getValidUrl,
	formatPhoneNumber,
	convertCurrencySystem,
	getAllDaysInMonth,
	getPosition,
	getTimelines,
	commaReplace,
	dataURLtoFile,
	getTerritoriesOutline,
	getTier,
	scrollToTheLeft,
	hideButton,
	getPriceInShort,
	validateEmail,
};
