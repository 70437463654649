import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { sortBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import base, { file as fileUploader } from '../apis';
import PlacesSearch from '../components/PlacesSearch/PlacesSearch';
import { states, timeZones } from '../constants';
import { ReactComponent as ImgPlac } from '../images/imgplaceholder.svg';
import { getValidUrl } from '../utils/functions';
import { Delete } from '@mui/icons-material';

// const mainInvestorOptions = [
// 	'Electrical',
// 	'Plumbing',
// 	'Roofing',
// 	'Insulation',
// 	'Flooring',
// 	'Handyman',
// 	'Landscaping',
// 	'Contracting',
// 	'Garage Door',
// 	'HVAC',
// 	'Home Staging',
// 	'Painting',
// 	'Design',
// 	'Fire Safety',
// 	'Cleaning',
// 	'Suppliers',
// 	'Architecture',
// 	'Abatement',
// 	'Junk Removal',
// 	'Financial',
// ];

export const mainWithOptions = [
	{
		name: 'Electrical',
		options: [
			'EV chargers',
			'Electrical wiring',
			'Emergency electrical services',
			'Landscape lighting',
			'Electrical repairs',
			'Rewiring',
			'Surge protector',
			'Ceiling fan installation',
			'Chandelier installation',
			'Custom lighting',
			'Electrical Construction',
			'Generator installation',
			'Outlet and switch repair',
			'Electrical inspections',
			'Remodels',
			'Safety inspections',
			'Appliance circuits',
			'Electrical upgrades',
			'Home automation',
			'Recessed lighting',
			'Smoke detectors',
			'Aluminum wiring',
			'Electrical maintenance',
			'Electrical panels',
		],
	},
	{
		name: 'Plumbing',
		options: [
			'Drain cleaning and unclogging',
			'Water heater maintenance',
			'Sewer line inspections and repairs',
			'Mold remediation',
			'Garbage disposal repairs and replacement',
			'Pipe restoration and excavation',
			'Water damage cleanup',
			'Leak repairs',
			'Sewer Line Install',
			'Piping',
			'Septics',
			'Well',
			'Wells',
			'Potable Water Systems',
			'Public Sewer Systems',
			'Buy Water Heater',
			'Water Heater Repair',
			'Water Heater Install',
			'Tankless Water Heater',
			'Tankless Water Heater Install',
			'Water Heater Replacement',
			'Tankless Water Heater Repair',
			'PVC',
			'ABS',
			'Sewer Line',
			'Copper Plumbing',
			'Galvanized Plumbing Repairs',
			'Galvanized Plumbing',
		],
	},
	{
		name: 'Roofing',
		options: [
			'Roof replacement',
			'Leak repair',
			'Roof patching',
			'Gutter installation and repair',
			'Roof inspections',
			'Flashing fabrication and installation',
			'New roof installation',
			'Moss Growth Removal',
			'Roof Install',
			'Tar & Gravel Roof',
			'Torch Down Roof',
			'Foam Roof',
			'Composition Roof',
			'Composition Asphalt Shingle Roof',
			'Asphalt Shingle Roof',
			'Gable Roof',
			'Flat Roof',
			'Gambrel Roof',
			'Mansard Roof',
			'Hip Roof',
			'Metal Roofing',
			'Slate Roof',
			'Butterfly Roof',
			'Concrete Tiles',
			'Shed Roof',
			'Dutch Gable Roof',
			'Wood Shake Roof',
			'Shake Roof',
			'Dormer',
			'Dormers',
			'Rubber Roofing',
			'Shingles',
			'Membrane Roofing',
			'Saltbox',
			'Gutter Install',
			'Sheathing Install',
			'Roof Sheathing Install',
			'Spanish Tiles Roof',
			'Spanish Roof Tiles',
			'Spanish Tiles',
			'Skylights',
			'Skylight Install',
			'Moss Growth',
			'Moss Removal',
		],
	},
	{
		name: 'Insulation',
		options: [
			'Wall insulation',
			'Spray foam',
			'Cellulose insulation',
			'Fiberglass batt insulation',
			'Glass wool',
			'Mineral wool',
			'Rigid board insulation',
			'BIBS insulation',
			'Floors',
			'Air sealing',
			'Blown In Insulation',
			'Blown Insulation',
			'Closed Cell Spray Foam: R-6 to R-7',
			'Foam Board: R-4.5 to R-5',
			'RetroFoam Injection Foam: R-4.6 to R-5',
			'Open Cell Spray Foam: R-3.6 to R-3.9',
			'Cellulose (Loose-Fill): R-3.1 to R-3.8',
			'Fiberglass (Batts): R-2.9 to R3.8',
			'Fiberglass (Loose-Fill): R-2.2 to R-2.9',
			'R-1 Insulation',
			'R-1.5',
			'R-2',
			'R-2.5',
			'R-3',
			'R-3.5',
			'R-4',
			'R-4.5',
			'R-4.6',
			'R-5.5',
			'R-6',
			'R-7',
		],
	},
	{
		name: 'Flooring',
		options: [
			'Tile',
			'Laminate flooring',
			'Carpet',
			'Hardwood flooring',
			'Vinyl Plank Flooring',
			'Engineered wood',
			'Linoleum',
			'Stone',
			'Cork',
			'Hardwood',
			'Vinyl',
			'Bamboo flooring',
			'Concrete',
			'Bamboo',
			'Cantera flooring',
			'Bathroom flooring',
			'Hybrid flooring',
			'Parquet flooring',
			'Resin flooring',
			'Carpet tiles',
			'Concrete floors',
			'Other types of flooring',
			'Flooring Install',
			'Flooring Removal',
			'Flooring Damage',
			'Flooring Repair',
		],
	},
	{
		name: 'Handyman',
		options: [
			'Plumbing',
			'Carpentry',
			'Painting',
			'Electricity',
			'Furniture assembly',
			'Pressure washing',
			'Deck repairs',
			'Gutter cleaning',
			'Smart home upgrades',
			'Tiling',
			'Fixture Replacement',
			'Repairs',
			'Fix fences',
			'Floor improvements',
			'HVAC',
			'Landscaping',
			'Art hanging',
			'Babyproofing',
			'Drywall repair',
			'Door adjustments',
			'Drywall',
			'Skylight installation',
			'Window coverings',
			'Door repair',
			'Miscellaneous Repairs',
			'Home Improvement',
		],
	},
	{
		name: 'Landscaping',
		options: [
			'Lawn mower',
			'Mulch',
			'Landscape design',
			'Weed control',
			'Fertilization',
			'Lawn aerator',
			'Pruning',
			'Mowing',
			'Pruning shrubs',
			'Snow removal',
			'Bush trimming',
			'Irrigation',
			'Dethatching',
			'Edging',
			'Planting',
			'Gutter cleaning',
			'Hedging',
			'Leaf removal',
			'Lawn returfing',
			'Trimming',
			'Lawn bed maintenance',
			'Pest control',
			'Clean up',
			'Sod Installation',
			'Grass Installation',
			'Flower Planting',
			'Plant Planting',
			'Tree Planting',
			'Tree Removal',
			'Irrigation startup or shutdown services',
		],
	},
	{
		name: 'General Contractor',
		options: [
			'Remodeling',
			'Remodel Kitchen',
			'Remodel Baths',
			'Framing',
			'Electrical',
			'Plumbing',
			'Roofing',
			'Flooring',
			'Tiles',
			'Landscaping',
			'Concrete',
			'Electrical Contactor',
			'Roofing Contractor',
			'Commercial Contractor',
			'GC',
			'General Contractor',
			'Residential Contractor',
			'New Construction',
			'Rebuild',
			'Demolition',
			'Demo',
			'Gut',
			'Insulation',
			'Foundation',
			'Structural Engineering',
		],
	},
	{
		name: 'Garage Door',
		options: ['Garage Door Repair', 'Garage Door Install', 'Garage Door', 'Buy Garage Door', 'Purchase Garage Door'],
	},

	{
		name: 'HVAC',
		options: ['Heat', 'Air', 'Duct Installation', 'Air Duct Install', 'Heating Duct Install', 'Duct Install', 'HVAC', 'Buy AC Unit', 'Buy Furnace'],
	},
	{
		name: 'Home Staging',
		options: ['Staging', 'Stage my House', 'Stager'],
	},
	{
		name: 'Painting',
		options: ['Painting', 'Painter'],
	},
	{
		name: 'Home Design',
		options: ['Homes Design', 'Homes Designer'],
	},
	{
		name: 'Fire Safety',
		options: [
			'Inspections',
			'Maintenance',
			'Fire Sprinkler System Design',
			'Fire Sprinkler',
			'Maintenance Inspections',
			'Fire Sprinkler Maintenance',
			'Fire Suppression',
			'FM 200 Inspections',
			'Kitchen Suppression',
			'Commercial Sprinklers',
			'Residential Sprinklers',
			'Residential Fire Sprinkler Inspections',
			'Commercial Fire Sprinkler Inspections',
			'Fire Sprinkler Install',
			'Fire Sprinkler Plumbing',
			'Fire Sprinkler Monitoring',
			'Fire Sprinkler System Repair',
		],
	},
	{
		name: 'House/ Home Cleaning',
		options: ['House Cleaning', 'Kitchen Cleaning', 'Bedroom Cleaning', 'Bathroom Cleaning', 'Dusting'],
	},
	{
		name: 'Kitchen Counter Suppliers',
		options: [
			'Solid Surface Countertops',
			'Butcher Block Countertops',
			'Laminate Countertops',
			'Soapstone',
			'Ceramic Tile',
			'Stainless Steel',
			'Kitchen Counters',
			'Granite Counter Tops',
			'Quartz Counter Tops',
			'Marble Counter Tops',
			'Kitchen Cabinets',
			'Custom Kitchen Cabinets',
		],
	},
	{
		name: 'Tile Suppliers',
		options: [
			'Bathroom Tile',
			'Flooring & Carpet',
			'Kitchen Flooring',
			'Material',
			'Ceramic',
			'Porcelain',
			'Stone',
			'Stores',
			'Home Depot',
			"Lowe's",
			'Walmart',
			'Square Tiles',
			'Rectangle Tiles',
			'Rectangular Tiles',
			'Hexagon Tiles',
			'Herringbone',
			'Matte',
			'Glossy',
			'Glazed',
			'Polished',
			'Large Format Tiles',
			'Standard Format Tiles',
			'Mosaic',
			'Features',
			'Outdoor Tiles',
			'Tiles With Water Protection',
			'Accent Tiles',
			'GREENGUARD Certified Tiles',
			'Pressed Tiles',
			'Straight Edge Tiles',
			'Rectified Tiles',
			'Contemporary Tiles',
			'Modern Tiles',
			'Farmhouse Tiles',
			'Classic Tiles',
			'Ivy Hill Tile',
			'Somertile',
			'MSI',
			'Daltile',
			'Marazzi Tile',
			'Emser Tile',
			'TrafficMASTER',
			'Bedrosians',
		],
	},
	{
		name: 'Window Suppliers',
		options: [
			'Awning windows',
			'Casement windows',
			'Double hung windows',
			'Single hung windows',
			'Sliding windows',
			'Picture windows',
			'Bay windows',
			'Bow windows',
			'Skylights',
			'Hopper windows',
			'Transom windows',
			'Garden windows',
			'Slider windows',
			'Fixed windows',
			'Glass block windows',
			'Jalousie windows',
			'Storm windows',
			'Egress windows',
			'Ingress windows',
			'Shaped windows',
			'Arched windows',
			'Round windows',
			'Casement',
			'Dormer window',
			'Single hung',
		],
	},
	{
		name: 'Architect',
		options: [
			'Residential Plans',
			'Commercial Plans',
			'Home Design',
			'Building Design',
			'Architectural Engineering',
			'Structural Engineering',
			'Plan Design',
			'Residential Architect',
			'Commercial Architect',
		],
	},
	{
		name: 'Mold Abatement',
		options: ['Mold Removal'],
	},
	{
		name: 'Junk Removal',
		options: ['Junk Removal', 'Junk Hauling', 'Demolition', 'Demo', 'Gut', 'Property Gutting'],
	},
	{
		name: 'Asbestos Abatement',
		options: ['Asbestos Removal'],
	},
	{
		name: 'Mortgage Brokers',
		options: ['Lending', 'Mortgage Rates', 'Loan'],
	},
	{
		name: 'Homes Insurance',
		options: ['Asbestos Removal'],
	},
];

const links = [
	{
		value: 'web_link',
		label: 'Web Link',
	},
	{ value: 'facebook', label: 'Facebook' },
	{
		value: 'instagram',
		label: 'Instagram',
	},
	{
		value: 'twitter',
		label: 'Twitter',
	},
	{
		value: 'youtube',
		label: 'YouTube',
	},
	{
		value: 'linkedin',
		label: 'LinkedIn',
	},
];

const MyListing = () => {
	const filePickerRef = useRef(null);
	const newFilePickerRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [listingData, setListingData] = useState(null);
	const [step, setStep] = useState(1);
	const [isNew, setIsNew] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const { data } = await base.get('register/ind-prof/profile/');
				let dataToUse = { ...data };
				if (!Object.keys(data).length) {
					setIsNew(true);
					dataToUse = {
						...dataToUse,
						company_name: '',
						industry: '',
						bio: '',
						services: [],
						address: '',
						state: '',
						county: '',
						zip_code: '',
						icon_file: null,
						timezone: '',
						formatted_phone_number: '',
						images: [],
						service_areas: [],
						mail_addresses: [],
						licenses: [],
					};
					links.forEach((i) => {
						dataToUse[i] = '';
					});
				}
				setListingData({
					...dataToUse,
					service_areas:
						dataToUse.service_areas.length === 0
							? [
									{
										location: '',
									},
							  ]
							: dataToUse?.service_areas,
					mail_addresses:
						dataToUse.mail_addresses.length === 0
							? [
									{
										name: '',
										suite_number: '',
									},
							  ]
							: dataToUse?.mail_addresses,
					licenses: dataToUse.licenses.length === 0 ? [{ state: '', description: '', expiration: '' }] : dataToUse?.licenses,
				});
			} catch (error) {
				toast.error('Could not get details');
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setListingData((v) => ({
			...v,
			[name]: value,
		}));
	};

	const setImage = async (e) => {
		const { files } = e.target;
		const file = files[0];
		if (file) {
			let formData = new FormData();
			formData.append('icon_file', file);
			try {
				const call = isNew
					? fileUploader.post(`register/ind-prof/create-profile/`, formData)
					: fileUploader.patch('register/ind-prof/profile/', formData);
				const { data } = await call;
				setListingData(data);
				setIsNew(false);
				filePickerRef.current.value = '';
			} catch (error) {
				toast.error('Could not upload image');
			} finally {
			}
		}
	};

	const [isImagesUploading, setIsImagesUploading] = useState(false);
	const setImages = async (e) => {
		const { files } = e.target;

		if (files.length > 0) {
			let formData = new FormData();
			for (let index = 0; index < files.length; index++) {
				formData.append(`images`, files[index]);
			}

			try {
				setIsImagesUploading(true);
				const call = isNew
					? fileUploader.post(`register/ind-prof/create-profile/`, formData)
					: fileUploader.patch('register/ind-prof/profile/', formData);
				const { data } = await call;
				setListingData(data);
				setIsNew(false);
			} catch (error) {
				toast.error('Could not upload image');
			} finally {
				setIsImagesUploading(false);
			}
		}
	};

	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const handleSave = async () => {
		//checks
		const body = {};
		if (step === 5) {
			setStep(1);
			return;
		}
		switch (step) {
			case 1:
				if (!listingData?.company_name) {
					toast.warn('Enter a company name');

					return;
				}
				if (!listingData?.industry) {
					toast.warn('Select an industry');
					return;
				}

				body.company_name = listingData?.company_name;
				body.industry = listingData?.industry;
				body.bio = listingData?.bio;
				body.services = listingData?.services;
				break;

			case 2:
				body.mail_addresses = listingData?.mail_addresses.filter((i) => i.name);
				body.address = listingData?.address;
				body.state = listingData?.state;
				body.county = listingData?.county;
				body.zip_code = listingData?.zip_code;
				body.timezone = listingData?.timezone;
				body.formatted_phone_number = listingData?.formatted_phone_number;
				break;

			case 3:
				body.licenses = listingData?.licenses?.filter((item) => item.description);
				body.service_areas = listingData?.service_areas?.filter((item) => item.location);
				break;
			case 4:
				links.forEach((i) => {
					body[i.value] = listingData?.[i.value] || '';
				});

				break;

			default:
				break;
		}
		//patch
		try {
			setIsSaveLoading(true);
			if (body) {
				const call = isNew ? base.post(`register/ind-prof/create-profile/`, body) : base.patch('register/ind-prof/profile/', body);
				const { data } = await call;
				setListingData(data);
				setIsNew(false);
			}
			setStep((v) => (v + 1 <= 5 ? v + 1 : 5));
		} catch (error) {
			toast.error('Could not update listing data');
		} finally {
			setIsSaveLoading(false);
		}
	};

	const onBlurField = (e) => {
		const { name, value } = e.target;
		setListingData({ ...listingData, [name]: getValidUrl(value) });
	};

	const changeStep = (step) => {
		// const form = document.getElementsByTagName('form')[0];
		// if (!form.checkValidity()) {
		// 	form?.reportValidity();
		// 	return;
		// }
		// validateData
		setStep(step);
	};

	const addMailingAddress = () => {
		if (listingData.mail_addresses.length === 2) {
			return;
		}
		setListingData((val) => ({ ...val, mail_addresses: [...val.mail_addresses, { name: '', suite_number: '' }] }));
	};

	const handleRemove = (index) => {
		setListingData((val) => {
			const mail_addresses = val.mail_addresses.filter((i, index1) => index1 !== index);
			if (mail_addresses.length === 0) mail_addresses[0] = { name: '', suite_number: '' };
			return { ...val, mail_addresses: mail_addresses };
		});
	};
	const handleRemoveServiceArea = (index) => {
		setListingData((val) => {
			const service_areas = val.service_areas.filter((i, index1) => index1 !== index);
			if (service_areas.length === 0) service_areas[0] = { location: '' };
			return { ...val, service_areas: service_areas };
		});
	};

	const deleteImage = async (id) => {
		const image = listingData?.images.find((i) => i.id === id);
		const imageIndex = listingData?.images.findIndex((i) => i.id === id);
		setListingData((v) => ({ ...v, images: v.images.filter((i) => i.id !== id) }));
		try {
			await base.post('professionals/image/delete/', { id });
		} catch {
			toast.warn('Could not delete image');
			setListingData((v) => {
				v.images.splice(imageIndex, 0, image);
				return { ...v, images: v.images };
			});
		}
	};

	const getContent = () => {
		switch (step) {
			case 1:
				// name, logo, description, industry
				return (
					<>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' alignItems='center'>
								<Title title={'Company Name'} />
								<TextField
									placeholder='Company Name'
									size='small'
									fullWidth
									sx={{ width: '60%' }}
									color='secondary'
									value={listingData?.company_name || ''}
									name='company_name'
									onChange={handleChange}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' alignItems='center'>
								<Title title={'Company Logo'} />
								<Stack>
									{listingData?.icon_file ? (
										<>
											<img
												src={listingData?.icon_file}
												alt='profile'
												style={{ width: '50px', aspectRatio: '1', borderRadius: '5px', cursor: 'pointer', objectFit: 'cover' }}
												onClick={() => {
													filePickerRef.current?.click();
												}}
											/>
											<label
												style={{
													background: 'var(--primary-color)',
													borderRadius: '3px',
													padding: '3px 10px',
													color: 'white',
													fontSize: '14px',
													marginTop: '10px',
												}}
												htmlFor='prof'
											>
												Choose Image
											</label>
											<input
												type='file'
												id='prof'
												accept='image/png, image/jpeg'
												ref={filePickerRef}
												onChange={setImage}
												style={{ display: 'none' }}
											/>
										</>
									) : (
										<>
											<Stack
												sx={{ width: '150px', aspectRatio: '1', borderRadius: '5px', border: '1px dashed grey', cursor: 'pointer' }}
												justifyContent='center'
												alignItems='center'
												onClick={() => {
													filePickerRef.current?.click();
												}}
											>
												<ImgPlac />
												<Typography fontSize={'9px'} fontWeight={500} color='secondary'>
													Click to choose file
												</Typography>
											</Stack>
											<input type='file' accept='image/png, image/jpeg' ref={filePickerRef} onChange={setImage} />
										</>
									)}
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem'>
								<Title title={'Company Description'} />
								<TextField
									placeholder='Company Description'
									size='small'
									multiline
									maxRows={6}
									rows={3}
									fullWidth
									sx={{ width: '60%' }}
									color='secondary'
									value={listingData?.bio || ''}
									name='bio'
									onChange={handleChange}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' alignItems='center'>
								<Title title='Industry' />
								<Autocomplete
									size='small'
									fullWidth
									disableClearable
									value={listingData?.industry || ''}
									onChange={(e, newValue) => {
										setListingData((v) => ({ ...v, industry: newValue, services: [] }));
									}}
									name='industry'
									sx={{ width: 300 }}
									options={sortBy(mainWithOptions.map((i) => i.name))}
									renderInput={(params) => <TextField size='small' color='secondary' {...params} fullWidth />}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem'>
								<Title title='Services' />
								<Stack width={'60%'} sx={{ flexWrap: 'wrap' }} gap='1rem' direction='row'>
									{sortBy(mainWithOptions.find((i) => i.name === listingData?.industry)?.options || []).map((item) => (
										<FormControlLabel
											label={item}
											key={item}
											control={
												<Checkbox
													color='secondary'
													checked={listingData?.services.includes(item)}
													onChange={(e) => {
														if (listingData?.services.includes(item))
															setListingData((v) => ({ ...v, services: v.services.filter((ser) => ser !== item) }));
														else setListingData((v) => ({ ...v, services: [...v.services, item] }));
													}}
												/>
											}
										/>
									))}
								</Stack>
							</Stack>
						</Grid>
					</>
				);

			case 2:
				//company address, mailing address, timezone, phone number
				return (
					<>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem'>
								<Title title={'Company Address'} />
								<Stack sx={{ width: '60%' }}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<PlacesSearch
												mui={true}
												placeholder='Company Address'
												size='small'
												fullWidth
												color='secondary'
												value={listingData?.address || ''}
												name='address'
												onChange={(e) => {
													setListingData((v) => ({ ...v, address: e }));
												}}
											/>
										</Grid>
										<Grid item xs={4}>
											<Autocomplete
												size='small'
												options={states.map((i) => i.value)}
												sx={{ flex: '1' }}
												onChange={(e, newValue) => {
													setListingData((v) => ({ ...v, state: newValue }));
												}}
												renderInput={(params) => <TextField size='small' color='secondary' {...params} label='State' fullWidth />}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												placeholder='County'
												size='small'
												color='secondary'
												name='county'
												fullWidth
												onChange={handleChange}
												value={listingData?.county || ''}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												placeholder='Zip Code'
												size='small'
												color='secondary'
												name='zip_code'
												fullWidth
												onChange={handleChange}
												value={listingData?.zip_code || ''}
											/>
										</Grid>
									</Grid>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							{listingData?.mail_addresses.map((item, index) => (
								<Stack direction='row' gap='2rem' mb='1rem' key={index}>
									<Title title={`Mailing Address ${1}`} />
									<Stack sx={{ width: '60%' }}>
										<Grid container spacing={2}>
											<Grid item xs={7}>
												<PlacesSearch
													mui={true}
													placeholder='Mailing Address'
													size='small'
													fullWidth
													color='secondary'
													value={listingData?.mail_addresses[index].name || ''}
													name='address'
													onChange={(e) => {
														const mailingaddress = listingData.mail_addresses;
														mailingaddress[index].name = e;
														setListingData((cur) => ({ ...cur, mail_addresses: mailingaddress }));
													}}
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													size='small'
													placeholder='Suite Number'
													fullWidth
													onChange={(e) => {
														const mailingaddress = listingData.mail_addresses;
														mailingaddress[index].suite_number = e.target.value;
														setListingData((cur) => ({ ...cur, mail_addresses: mailingaddress }));
													}}
													value={listingData.mail_addresses[index].suite_number || ''}
												/>
											</Grid>
											<Grid item xs={1}>
												<Button
													variant='text'
													color='error'
													sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto' }}
													onClick={() => handleRemove(index)}
												>
													Remove
												</Button>
											</Grid>

											{/* <Grid item xs={4}>
											<Autocomplete
												size='small'
												options={states.map((i) => i.value)}
												sx={{ flex: '1' }}
												renderInput={(params) => <TextField size='small' color='secondary' {...params} label='State' fullWidth />}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField placeholder='County' size='small' color='secondary' name='county' fullWidth />
										</Grid>
										<Grid item xs={4}>
											<TextField placeholder='Zip Code' size='small' color='secondary' name='zip_code' fullWidth />
										</Grid> */}
										</Grid>
									</Stack>
								</Stack>
							))}
							<Button
								variant='text'
								color='secondary'
								sx={{ textTransform: 'capitalize', width: 'fit-content', marginLeft: '22.5%' }}
								onClick={addMailingAddress}
							>
								Add Mailing Address
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' alignItems='center'>
								<Title title={'Timezone'} />
								<Autocomplete
									size='small'
									sx={{ width: 300 }}
									options={timeZones.map((item) => item.value)}
									disableClearable
									value={listingData?.timezone || ''}
									onChange={(e, newValue) => {
										setListingData((v) => ({ ...v, timezone: newValue }));
									}}
									renderInput={(params) => <TextField size='small' color='secondary' {...params} fullWidth />}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' alignItems='center'>
								<Title title={'Phone Number'} />
								<TextField
									placeholder='Phone Number'
									size='small'
									fullWidth
									sx={{ width: '60%' }}
									color='secondary'
									value={listingData?.formatted_phone_number}
									name='formatted_phone_number'
									onChange={handleChange}
								/>
							</Stack>
						</Grid>
					</>
				);

			case 3:
				//license , Service areas
				return (
					<>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem'>
								<Title title='License:' />
								<Stack gap='1rem'>
									{listingData?.licenses.map((item, index) => (
										<Grid container columnSpacing={0} rowSpacing={2} sx={{ width: '60%' }} key={index}>
											<Grid item xs={4}>
												<Title title={'State'} sx={{ width: '100%' }} />
											</Grid>
											<Grid item xs={8}>
												<Autocomplete
													size='small'
													disableClearable
													options={states.map((i) => i.value)}
													value={listingData?.licenses[index].state}
													onChange={(e, nV) => {
														const licenses = listingData.licenses;
														licenses[index].state = nV;
														setListingData((cur) => ({ ...cur, licenses: licenses }));
													}}
													renderInput={(params) => <TextField size='small' color='secondary' {...params} label='State' fullWidth />}
												/>
											</Grid>
											<Grid item xs={4}>
												<Title title={'License Number'} sx={{ width: '100%' }} />
											</Grid>
											<Grid item xs={8}>
												<TextField
													placeholder='License Number'
													size='small'
													fullWidth
													color='secondary'
													value={listingData?.licenses[index].description}
													onChange={(e) => {
														const licenses = listingData.licenses;
														licenses[index].description = e.target.value;
														setListingData((cur) => ({ ...cur, licenses: licenses }));
													}}
												/>
											</Grid>
											<Grid item xs={4}>
												<Title title={'Expiration Date'} sx={{ width: '100%' }} />
											</Grid>
											<Grid item xs={8}>
												<input
													type='date'
													value={listingData?.licenses[index].expiration}
													onChange={(e) => {
														const licenses = listingData.licenses;
														licenses[index].expiration = e.target.value;
														setListingData((cur) => ({ ...cur, licenses: licenses }));
													}}
													style={{ padding: '10px', borderRadius: '5px', border: '1px solid #c4c4c4', fontFamily: 'inherit', width: '100%' }}
												/>
											</Grid>
										</Grid>
									))}
									<Button
										variant='text'
										color='secondary'
										sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto' }}
										onClick={() => {
											setListingData((v) => ({ ...v, licenses: [...v.licenses, { state: '', description: '', expiration: '' }] }));
										}}
									>
										Add License
									</Button>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem'>
								<Title title='Service Areas' />
								<Stack gap='1rem' sx={{ width: '60%' }}>
									{listingData?.service_areas.map((item, index) => (
										<Stack direction='row' gap='1rem' key={index}>
											<PlacesSearch
												mui={true}
												types={['country', 'locality']}
												placeholder='Service Areas'
												size='small'
												fullWidth
												color='secondary'
												value={listingData.service_areas[index].location || ''}
												onChange={(e) => {
													const serviceArea = listingData.service_areas;
													serviceArea[index].location = e;
													setListingData((cur) => ({ ...cur, service_areas: serviceArea }));
												}}
											/>
											<Button
												variant='text'
												color='error'
												sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto' }}
												onClick={() => handleRemoveServiceArea(index)}
											>
												Remove
											</Button>
										</Stack>
									))}

									<Button
										variant='text'
										color='secondary'
										sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto' }}
										onClick={() => {
											setListingData((v) => ({ ...v, service_areas: [...v.service_areas, { location: '' }] }));
										}}
									>
										Add Service Area
									</Button>
								</Stack>
							</Stack>
						</Grid>
					</>
				);
			case 4:
				//links
				return (
					<>
						{links.map((social) => (
							<Grid item xs={12} key={social.value}>
								<Stack direction='row' gap='2rem' alignItems='center'>
									<Title title={social.label} />
									<TextField
										placeholder={social.label}
										size='small'
										fullWidth
										onBlur={onBlurField}
										sx={{ width: '60%' }}
										color='secondary'
										value={listingData?.[social.value]}
										name={social.value}
										onChange={handleChange}
									/>
								</Stack>
							</Grid>
						))}
					</>
				);

			case 5:
				return (
					<>
						<Typography fontSize={'25px'} fontWeight={700}>
							Images for listing
						</Typography>
						<Grid container spacing={2}>
							{listingData.images.map((i) => (
								<Grid item xs={3} key={i.id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
									<img src={i.image} alt='listing' style={{ width: '300px', aspectRatio: '1', objectFit: 'cover' }} />
									<IconButton color='error'>
										<Delete onClick={() => deleteImage(i.id)} />
									</IconButton>
								</Grid>
							))}

							{isImagesUploading && (
								<Grid item xs={3}>
									<Stack alignItems='center' justifyContent={'center'} sx={{ width: '300px' }}></Stack>
								</Grid>
							)}

							<Grid item xs={3}>
								<input type='file' accept='image/*' ref={newFilePickerRef} style={{ display: 'none' }} multiple onChange={setImages} />
								<Stack
									sx={{ width: '300px', aspectRatio: '1', borderRadius: '5px', border: '1px dashed grey', cursor: 'pointer' }}
									justifyContent='center'
									alignItems='center'
									onClick={() => {
										newFilePickerRef.current?.click();
									}}
								>
									<ImgPlac />
									<Typography fontSize={'12px'} fontWeight={500} color='secondary'>
										Click to choose file
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					</>
				);

			default:
				break;
		}
	};

	if (isLoading) {
		return (
			<Stack sx={{ minHeight: '100vh' }} alignItems='center' justifyContent='center'>
				<CircularProgress color='secondary' />
			</Stack>
		);
	}
	return (
		<Stack sx={{ minHeight: '100vh' }} p='1rem 3rem' gap='1rem'>
			<Typography color='#1378A5' fontWeight={700} fontSize={30}>
				{isNew ? 'Add' : 'Edit'} Your Listing
			</Typography>
			<div className='pedit_h'>
				<div className='pedit__steps'>
					{[1, 2, 3, 4, 5].map((item) => (
						<Tooltip key={item} title={`Step ${item}`}>
							<div
								style={{ cursor: 'pointer', height: '10px' }}
								className={item <= step ? 'pedit__step pedit__step-active' : 'pedit__step'}
								onClick={() => changeStep(item)}
							></div>
						</Tooltip>
					))}
				</div>
				<div>{step} of 5</div>
			</div>
			<Grid container spacing={2}>
				{getContent()}

				<Grid item xs={12}>
					<Stack direction='row' justifyContent={'space-between'} gap='2rem' sx={{ width: '82%', mt: '2rem' }}>
						<Stack sx={{ width: '20%' }}>
							{step > 1 && (
								<Button
									sx={{ textTransform: 'capitalize', width: 'fit-content' }}
									variant='outlined'
									color='secondary'
									onClick={() => setStep((v) => v - 1)}
								>
									Previous
								</Button>
							)}
						</Stack>
						<Stack direction='row' gap='2rem' sx={{ justifyContent: 'flex-end' }}>
							<LoadingButton
								loading={isSaveLoading}
								sx={{ textTransform: 'capitalize', width: 'fit-content' }}
								variant='contained'
								color='secondary'
								onClick={handleSave}
							>
								{step === 5 ? 'Go To Start' : 'Save & Continue'}
							</LoadingButton>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default MyListing;

const Title = ({ title, sx }) => {
	return (
		<Typography sx={{ width: '20%', ...sx }} fontWeight={700}>
			{title}
		</Typography>
	);
};
