import { ExpandMore, MoreVert } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Button as NButton,
	Pagination,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import base from '../apis';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers';
import DateLocalizer from '../components/DateLocalizer';
import dayjs from 'dayjs';

const Button = styled(NButton)(({ theme, isactive }) => ({
	textTransform: 'capitalize',
	backgroundColor: isactive ? theme.secondaryColor : '#EAF6FF',
	color: isactive ? 'white' : '#5292C5',
	fontFamily: 'Inter',
	fontSize: '16px',
	fontWeight: 500,
	'&:hover': {
		backgroundColor: isactive ? theme.secondaryColor : '#EAF6FF',
		color: isactive ? 'white' : '#5292C5',
	},
}));

Button.defaultProps = {
	disableElevation: true,
	disableFocusRipple: true,
	disableRipple: true,
	disableTouchRipple: true,
};

const useStyles = makeStyles((theme) => ({
	accSum: {
		boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
		borderRadius: '5px',
	},
	header: {
		color: theme.secondaryColor,
		fontSize: '18px',
	},
}));

const Followups = () => {
	const [followups, setFollowups] = useState([]);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [isSent, setIsSent] = useState(false);
	const [followUp, setFollowUp] = useState({
		date: null,
		message: '',
	});

	const { indPro } = useSelector((state) => state.subscriptions);
	const navigate = useNavigate();

	useEffect(() => {
		if (!indPro) {
			navigate('/industry-prof-subscription');
		}
	}, [indPro, navigate]);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const { data } = await base.get('professionals/follow-up/', {
					params: {
						is_sent: isSent,
					},
				});
				setCount(data.count);
				setPage(0);
				setFollowups(data.results);
			} catch (error) {
				toast.warn(error?.response?.data?.detail || 'Could not get followups');
			} finally {
				setIsLoading(false);
			}
		})();
	}, [isSent]);

	const handlePageChange = async (e, page) => {
		setPage(page);
		try {
			const { data } = await base.get('professionals/quotes', {
				params: { page: page + 1 },
			});
			setCount(data.count);
			setFollowups(data.results);
		} catch (error) {
			toast.warn('Could not get requested quotes');
		} finally {
			setIsLoading(false);
		}
	};

	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const [isFollowupLoading, setIsFollowupLoading] = useState(false);

	const handleEditFollowup = async () => {
		if (!followUp.message) {
			toast.warn('Please enter a follow up message');
			return;
		}
		if (!followUp.date) {
			toast.warn('Please select a date');
			return;
		}
		setIsFollowupLoading(true);
		try {
			await base.patch(`professionals/follow-up/${followUp?.id}/`, {
				send_date: followUp.date?.format(),
				message: followUp.message,
			});
			setFollowups((v) => {
				const toChangeIndex = v.findIndex((i) => i.id === followUp.id);
				v[toChangeIndex].send_date = followUp.date;
				v[toChangeIndex].message = followUp.message;
				return v;
			});
			toast.success('Follow up saved successfully');
			setDetailsModalOpen(false);
		} catch (error) {
			toast.error('Could not save follow up');
		} finally {
			setDetailsModalOpen(false);
		}
	};

	const openFollowup = (item) => {
		setFollowUp({ id: item.id, message: item.message, date: dayjs(item.send_date) });
		setDetailsModalOpen(true);
	};

	return (
		<>
			<Dialog fullWidth maxWidth='sm' open={detailsModalOpen} onClose={() => setDetailsModalOpen(false)}>
				<DialogTitle
					sx={{
						background: 'linear-gradient(90deg, rgba(19, 120, 165, 0.15) 0%, rgba(0, 94, 169, 0) 100%)',
						boxShadow: '0px 0px 10px 0px #00000033',
					}}
				>
					<Typography fontWeight={600} fontSize={'20px'}>
						Edit Follow Up
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack gap='1rem' mt='1rem'>
						<Typography>Date & Time</Typography>
						<DateLocalizer>
							<DateTimePicker
								value={followUp.date}
								onChange={(value) => setFollowUp((v) => ({ ...v, date: value }))}
								minDate={dayjs()}
								label='Date & Time'
							/>
						</DateLocalizer>

						<Typography>Follow Up Message</Typography>
						<TextField
							multiline
							rows={4}
							placholder='Follow up message'
							color='secondary'
							value={followUp.message}
							onChange={(e) => setFollowUp((v) => ({ ...v, message: e.target.value }))}
						/>
						<Stack direction='row' justifyContent={'flex-end'} gap='1rem' mt='2rem'>
							<Button variant='outlined' color='secondary' onClick={() => setDetailsModalOpen(false)}>
								Close
							</Button>
							<LoadingButton
								variant='contained'
								color='secondary'
								sx={{ textTransform: 'capitalize' }}
								type='submit'
								onClick={handleEditFollowup}
								loading={isFollowupLoading}
							>
								Update
							</LoadingButton>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
			<Stack p='1rem' sx={{ minHeight: '100vh' }} gap='1rem'>
				<Stack direction='row' gap='1rem'>
					<Button variant='contained' color='secondary' isactive={!isSent} onClick={() => setIsSent(false)}>
						Unsent
					</Button>
					<Button variant='contained' color='secondary' isactive={isSent} onClick={() => setIsSent(true)}>
						Sent
					</Button>
				</Stack>
				{isLoading ? (
					<TableSkeleton />
				) : (
					<Stack gap='1rem' alignItems='center'>
						<Stack sx={{ width: '100%' }}>
							{followups.map((item) => (
								<ModAccordion key={item.id} {...item} onClick={() => openFollowup(item)} />
							))}
						</Stack>
						<Pagination page={page} count={Math.ceil(count / 12)} onChange={handlePageChange} color='secondary' variant='outlined' shape='rounded' />
					</Stack>
				)}
			</Stack>
		</>
	);
};

export default Followups;

const TableSkeleton = () => {
	return (
		<Stack gap='3px'>
			{Array.from(Array(10).keys()).map((_) => (
				<Skeleton variant='rectangular' width='100%' height={100} key={_} />
			))}
		</Stack>
	);
};

export const ModAccordion = ({ request_quote: { first_name, last_name, email }, message, afterSent, send_date, onClick, id }) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [isNowSending, setIsNowSending] = useState(false);
	const sendFollowUpNow = async () => {
		try {
			setIsNowSending(true);
			await base.post('professionals/send-follow-up/', {
				id,
			});
		} catch (error) {
			toast.warn('Could not send follow up now');
		} finally {
			setIsNowSending(false);
		}
	};

	return (
		<>
			<Accordion elevation={0}>
				<AccordionSummary expandIcon={<ExpandMore />} className={classes.accSum}>
					<Grid container spacing={2}>
						<Grid item xs={4} className={classes.header}>
							Name
						</Grid>
						<Grid item xs={4} className={classes.header}>
							Email
						</Grid>
						<Grid item xs={4} className={classes.header}>
							Follow Up Date
						</Grid>
						<Grid item xs={4}>
							{first_name} {last_name}
						</Grid>
						<Grid item xs={4}>
							{email}
						</Grid>
						<Grid item xs={4}>
							{new Date(send_date).toLocaleDateString('en-US', {
								year: '2-digit',
								month: 'short',
								day: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
							})}
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<Stack direction='row' gap='1rem' alignItems='flex-start'>
						<Typography flex={'1'}>{message}</Typography>
						<LoadingButton
							variant='contained'
							loading={isNowSending}
							onClick={sendFollowUpNow}
							color='secondary'
							sx={{ textTransform: 'capitalize' }}
						>
							Follow up now
						</LoadingButton>
						<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
							<MenuItem
								onClick={() => {
									handleClose();
									onClick();
								}}
							>
								Edit Follow up
							</MenuItem>

							{/* <MenuItem>Edit Follow up Date</MenuItem> */}
						</Menu>
						<IconButton onClick={handleClick}>
							<MoreVert />
						</IconButton>
					</Stack>
				</AccordionDetails>
			</Accordion>
		</>
	);
};
