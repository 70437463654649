import { Close, Phone, PlaceOutlined, Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Pagination,
	Paper,
	Rating,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleAuthModal } from '../actions';
import base from '../apis';
import { URL } from '../constants';
import { ReactComponent as AreaMap } from '../images/area-map.svg';
import BG from '../images/industBG.jpg';
import { ReactComponent as StarIn } from '../images/StarInd.svg';
import { mainWithOptions } from './MyListing';
import { useNavigate } from 'react-router-dom';
import PlacesSearch from '../components/PlacesSearch/PlacesSearch';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 'calc(100vh - var(--navheight))',
	},
	banner: {
		background: `url(${BG})`,
		height: '70vh',
		width: '100%',
	},
	leftBox: {
		'& .MuiOutlinedInput-root': {
			backgroundColor: 'white',
			borderTopRightRadius: `0`,
			borderBottomRightRadius: `0`,
			padding: '0',
			paddingLeft: '10px',
			'& fieldset': {
				borderTopRightRadius: `0`,
				borderBottomRightRadius: `0`,
				color: 'black',
			},
		},
	},
	middleBox: {
		'& .MuiOutlinedInput-root': {
			backgroundColor: 'white',
			borderRadius: `0`,
			padding: '0',
			paddingLeft: '10px',
			minHeight: '56px',

			'& fieldset': {
				borderRadius: `0`,
				color: 'black',
			},
		},
	},
	rightBox: {
		'& .MuiOutlinedInput-root': {
			backgroundColor: 'white',
			borderTopLeftRadius: `0`,
			borderBottomLeftRadius: `0`,
			padding: '0',
			paddingLeft: '10px',

			'& fieldset': {
				borderTopLeftRadius: `0`,
				borderBottomLeftRadius: `0`,
				color: 'black',
			},
		},
	},
}));

const options = [];
mainWithOptions.forEach((i) => {
	i.options.forEach((el) => {
		options.push({
			label: el,
			name: i.name,
		});
	});
});

const IndustryProfessionals = () => {
	const getProfs = async (page) => {
		try {
			setIsLoading(true);
			const params = {
				page: page || 1,
				// page_size: 12,
			};
			if (searchParams.company_name) params.company_name__icontains = searchParams.company_name;
			if (searchParams.industry) params.industry__icontains = searchParams.industry;
			if (searchParams.city) params.city__icontains = searchParams.city;

			const [response1, response2] = await Promise.all([
				axios.get(URL + 'professionals/', {
					params: params,
				}),
				axios.get(URL + 'professionals/paid'),
			]);
			const agents = response1.data;
			const paidAgents = response2.data;
			setAgents(agents.results);
			setPaidAgents(paidAgents.results);
			setCount(agents.count);
			if (!page) setPage(1);
		} catch (error) {
			toast.error('Something went wrong');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getProfs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const classes = useStyles();
	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const [isBioShort, setIsBioShort] = useState(true);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [agents, setAgents] = useState([]);
	const [paidAgents, setPaidAgents] = useState([]);
	const [count, setCount] = useState(1);
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const [searchParams, setSearchParams] = useState({
		name: '',
		industry: '',
		city: '',
		service: '',
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onFind = React.useCallback(
		debounce((obj) => {
			getSearchResults(obj);
		}, 500),
		[]
	);
	const getSearchResults = async (obj) => {
		const params = {
			page_size: 12,
		};
		if (obj.business_name) params.company_name__icontains = obj.business_name;
		if (obj.industry) params.industry__icontains = obj.industry;
		if (obj.city) params.city__icontains = obj.city?.split(',')[0];
		if (obj.service) params.service__icontains = obj.service;
		//add service

		try {
			setIsLoading(true);
			const [response1, response2] = await Promise.all([
				axios.get(URL + 'professionals/', {
					params: params,
				}),
				axios.get(URL + 'professionals/paid', {
					params,
				}),
			]);
			const data = response1.data;
			const paidAgents = response2.data;

			setAgents(data.results);
			setPaidAgents(paidAgents.results);
			setCount(data.count);
			setPage(1);
		} catch (error) {
			toast.error('Something went wrong');
		} finally {
			setIsLoading(false);
		}
	};

	const handleParamChange = (e) => {
		const newChange = {
			...searchParams,
			[e.target.name]: e.target.value,
		};
		setSearchParams(newChange);
		onFind({ ...newChange });
	};

	const handlePageChange = async (e, page) => {
		getProfs(page);
		setPage(page);
	};

	const loggedIn = useSelector((state) => state?.auth?.token?.access);
	const role = useSelector((state) => state?.auth.role);
	const dispatch = useDispatch();

	const [isClaimLoading, setIsClaimLoading] = useState(false);
	const claimListing = async () => {
		if (!loggedIn) {
			setDetailsModalOpen(false);
			dispatch(toggleAuthModal());
			localStorage.setItem('nextRoute', '/industry-professionals');
			return;
		}

		if (role !== 'ind_prof') {
			toast.warn('Only industry professionals can claim listings');
			return;
		}

		try {
			setIsClaimLoading(true);
			const { data } = await base.post('professionals/claim/', {
				id: selectedCompany.id,
			});
			setSelectedCompany(data);
			setAgents((val) => {
				const index = val.findIndex((item) => item.id === selectedCompany.id);

				if (index !== -1) {
					val[index] = data;
				}
				return val;
			});
			toast.success('Listing claimed. Update details in your dashboard');
		} catch (error) {
			toast.warn(error?.response?.data?.message || 'An error occurred');
		} finally {
			setIsClaimLoading(false);
		}
	};

	const navigate = useNavigate();

	const handleClick = () => {
		if (!loggedIn) {
			dispatch(toggleAuthModal());
		} else {
			if (role !== 'ind_prof') {
				toast.warn('Only industry professionals can claim listings');
				return;
			}
			navigate('/my-listing');
		}
	};

	const [serviceOptions, setServiceOptions] = useState([]);

	return (
		<>
			<Dialog fullWidth maxWidth='sm' open={detailsModalOpen} onClose={() => setDetailsModalOpen(false)}>
				<DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Stack sx={{ width: '100%' }}>
						<Stack direction='row' justifyContent={'space-between'} sx={{ width: '100%' }}>
							<Box display='flex' alignItems='center' gap='10px'>
								<Stack direction='row' gap='1rem'>
									<Avatar src={selectedCompany?.icon_file} />
									<Stack>
										<Typography fontFamily={'Inter'} fontSize='16px' fontWeight={700}>
											{selectedCompany?.company_name}
										</Typography>
										<Typography fontFamily={'Inter'} fontSize='12px' fontWeight={300}>
											{selectedCompany?.industry}
										</Typography>
									</Stack>
								</Stack>
							</Box>
							<IconButton onClick={() => setDetailsModalOpen(false)}>
								<Close fontSize='small' />
							</IconButton>
						</Stack>
						<Divider style={{ marginTop: '1rem', width: '100%', background: 'var(--primary-color)' }} />
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Stack gap='1rem'>
						{/* <AboutWithIcons style={{ fontSize: '16px' }} /> */}
						<Grid container rowSpacing={1} alignItems='center'>
							<Typography
								sx={{
									whiteSpace: 'pre-line',
									fontWeight: '300',
									fontSize: '16px',
									display: isBioShort ? '-webkit-box' : 'block',
									WebkitLineClamp: '3',
									' -webkit-box-orient': 'vertical',
									overflow: isBioShort ? 'hidden' : 'auto',
									textOverflow: 'ellipsis',
									marginTop: '1rem',
								}}
							>
								{selectedCompany?.bio}
							</Typography>
							<Grid item xs={12}>
								{selectedCompany?.bio?.length > 200 && (
									<Button
										variant='text'
										sx={{
											textTransform: 'capitalize',
											marginTop: 1,
											'&:hover': { backgroundColor: 'transparent' },
											paddingLeft: 0,
											justifyContent: 'flex-start',
										}}
										color='secondary'
										onClick={() => setIsBioShort((v) => !v)}
									>
										<Typography fontSize='16px' fontWeight='500'>
											{isBioShort ? 'Show more' : 'Hide'}
										</Typography>
									</Button>
								)}
							</Grid>
							<Grid item xl={1} md={2} xs={2} sm={2}>
								<PlaceOutlined />
							</Grid>
							<Grid item xl={11} md={10} xs={10} sm={10} display='flex' flexDirection='column'>
								<Typography variant='body2' fontWeight='700' fontSize='14px' fontFamily='Inter'>
									{selectedCompany?.address}
								</Typography>
								<Typography fontSize='14px' fontWeight='400' color='#A2A2A2' fontFamily='Inter'>
									Business Address
								</Typography>
							</Grid>
							<Grid item xl={1} md={2} xs={2} sm={2}>
								<Phone />
							</Grid>
							<Grid item xl={11} md={10} xs={10} sm={10} display='flex' flexDirection='column'>
								<Typography variant='body2' fontWeight='700' fontSize='14px' fontFamily='Inter'>
									{selectedCompany?.formatted_phone_number}
								</Typography>
								<Typography fontSize='14px' fontWeight='400' color='#A2A2A2' fontFamily='Inter'>
									Phone Number
								</Typography>
							</Grid>

							<Grid item xl={1} md={2} xs={2} sm={2}>
								<AreaMap />
							</Grid>
							<Grid item xl={11} md={10} xs={10} sm={10} display='flex' flexDirection='column'>
								<Typography variant='body2' fontWeight='700' fontSize='14px' fontFamily='Inter'>
									{selectedCompany?.service_areas?.map((location) => location.location)?.join(', ') || 'Not Provided'}
								</Typography>
								<Typography fontSize='14px' fontWeight='400' color='#A2A2A2' fontFamily='Inter'>
									Service Areas
								</Typography>
							</Grid>
							{/* <Grid item xl={1} md={2} xs={2} sm={2}>
								<LanguageIcon />
							</Grid> */}
							{/* <Grid item xl={11} md={10} xs={10} sm={10} display='flex' flexDirection='column'>
								<Typography variant='body2' fontWeight='700' fontSize='14px' fontFamily='Inter'>
								</Typography>
								<Typography fontSize='14px' fontWeight='400' color='#A2A2A2' fontFamily='Inter'>
									Languages
								</Typography>
							</Grid> */}
						</Grid>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Stack direction='row' justifyContent={'flex-end'} gap='1rem'>
						<Button variant='outlined' color='secondary' sx={{ textTransform: 'capitalize' }} href={`/industry-professionals/${selectedCompany?.id}`}>
							Visit Website
						</Button>
						{!selectedCompany?.user?.id && (
							<LoadingButton
								variant='contained'
								disableElevation
								color='secondary'
								sx={{ textTransform: 'capitalize', background: `linear-gradient(97.59deg, #005EA9 21.36%, #00213B 106.67%)` }}
								onClick={claimListing}
								loading={isClaimLoading}
							>
								Claim Business
							</LoadingButton>
						)}
					</Stack>
				</DialogActions>
			</Dialog>
			<Stack className={classes.root}>
				<Stack className={classes.banner} justifyContent={'center'} alignItems='center' gap='1rem'>
					<Typography fontWeight={900} fontFamily='Inter' fontSize={'30px'} color='white' textAlign='center'>
						Find Your Industry Professional
					</Typography>
					<Box
						display='flex'
						justifyContent='center'
						alignItems='center'
						gap='10px'
						sx={{
							flexDirection: {
								sm: 'column',
								xs: 'column',
								md: 'row',
								lg: 'row',
								xl: 'row',
							},
						}}
					>
						<Grid
							container
							spacing={0}
							px='1rem'
							sx={{
								width: {
									xs: '100%',
									sm: '100%',
									md: '100%',
									lg: '60vw',
									xl: '60vw',
								},
								// margin: 'auto',
							}}
						>
							<Grid item xs={12} lg={3} sm={3} md={3}>
								<TextField
									size='medium'
									color='secondary'
									fullWidth
									value={searchParams.business_name}
									name='business_name'
									onChange={handleParamChange}
									InputProps={{
										startAdornment: (
											<InputAdornment position='end' sx={{ marginRight: '10px' }}>
												<Search />
											</InputAdornment>
										),
									}}
									placeholder='Business name'
									className={classes.leftBox}
								/>
							</Grid>
							<Grid item xs={12} lg={3} sm={3} md={3}>
								<Autocomplete
									size='medium'
									fullWidth
									value={searchParams?.industry || null}
									onChange={(e, newValue) => {
										const newChange = {
											...searchParams,
											industry: newValue,
											service: '',
										};
										const index = mainWithOptions.findIndex((el) => el.name === newValue);
										if (index !== -1) {
											setServiceOptions(mainWithOptions[index].options);
										}
										setSearchParams(newChange);
										onFind({ ...newChange });
									}}
									name='industry'
									renderInput={(params) => (
										<TextField color='secondary' {...params} fullWidth placeholder='Industry' className={classes.middleBox} size='medium' />
									)}
									options={mainWithOptions.map((item) => item.name)}
								/>
							</Grid>
							<Grid item xs={12} lg={3} sm={3} md={3}>
								<Autocomplete
									renderInput={(params) => (
										<TextField color='secondary' {...params} fullWidth placeholder='Service' className={classes.middleBox} size='medium' />
									)}
									size='medium'
									fullWidth
									value={searchParams?.service || null}
									onChange={(e, newValue) => {
										const newChange = {
											...searchParams,
											service: newValue,
										};

										setSearchParams(newChange);
										onFind({ ...newChange });
									}}
									name='service'
									options={serviceOptions || []}
								/>
							</Grid>

							<Grid item xs={12} lg={3} sm={3} md={3}>
								<PlacesSearch
									mui
									types={['(cities)']}
									size='medium'
									color='secondary'
									value={searchParams.city}
									name='city'
									onChange={(value) => handleParamChange({ target: { value: value, name: 'city' } })}
									InputProps={{
										startAdornment: (
											<InputAdornment position='end' sx={{ marginRight: '10px' }}>
												<Search />
											</InputAdornment>
										),
									}}
									fullWidth
									placeholder='City'
									className={classes.rightBox}
								/>
							</Grid>
						</Grid>
					</Box>
					<Stack alignItems={'center'} mt='5rem'>
						<Typography fontSize={30} color='#fff' fontWeight={700}>
							Is Your Business Not Listed?
						</Typography>
						<Button sx={{ textTransform: 'capitalize' }} variant='contained' onClick={handleClick}>
							Sign Up FREE Now!
						</Button>
					</Stack>
				</Stack>

				{isLoading ? (
					<LoadingScreen />
				) : (
					<>
						<Stack
							p={{
								xs: '2rem 1rem',
								md: '2rem 5rem',
								lg: '2rem 5rem',
								xl: '2rem 5rem',
							}}
							gap='1rem'
						>
							{paidAgents.length > 0 && (
								<>
									<Titler title='Featured Professionals' />
									<Grid container spacing={2} mb='3rem'>
										{paidAgents?.map((item) => (
											<Grid item xs={12} key={item.id} md={4} lg={4} xl={4}>
												<IndCard
													isPrem
													{...item}
													onClick={() => {
														setSelectedCompany(item);
														setDetailsModalOpen(true);
													}}
												/>
											</Grid>
										))}
									</Grid>
								</>
							)}
							<Titler title='Professionals' />
							<Grid container spacing={2}>
								{agents?.map((item) => (
									<Grid item xs={12} key={item.id} md={4} lg={3} xl={3}>
										<IndCard
											{...item}
											onClick={() => {
												setSelectedCompany(item);
												setDetailsModalOpen(true);
											}}
										/>
									</Grid>
								))}
							</Grid>
							<Pagination
								size='medium'
								color='secondary'
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
								shape='rounded'
								variant='outlined'
								page={page}
								count={Math.ceil(count / 12)}
								onChange={handlePageChange}
							/>
						</Stack>
					</>
				)}
			</Stack>
		</>
	);
};

export default IndustryProfessionals;

const Titler = ({ title }) => {
	return (
		<Stack direction='row' alignItems='center' sx={{ minWidth: '100%' }} gap='1rem' mr='auto'>
			<Typography sx={{ textWrap: 'nowrap' }}>{title}</Typography>
			<Box sx={{ height: '1px', flex: 1, backgroundColor: '#B9B9B9' }}></Box>
			{/* <Divider flexItem sx={{ height: '1px', backgroundColor: '#B9B9B9', marginBottom: '5px', flex: 1 }} /> */}
		</Stack>
	);
};

const IndCard = ({ isPrem = false, onClick, company_name, industry, rating, reviews, icon_file, bio }) => {
	return (
		<Paper elevation={1} square sx={{ height: '100%' }}>
			<Stack gap='1rem' p='1rem' sx={{ position: 'relative', cursor: 'pointer' }} onClick={onClick}>
				{isPrem && (
					<>
						<Stack
							alignItems='flex-end'
							justifyContent='flex-start'
							sx={{
								position: 'absolute',
								top: 0,
								right: 0,
								width: 0,
								height: 0,
								borderTop: '30px solid #02467C',
								borderRight: '30px solid #02467C',
								borderBottom: '30px solid transparent',
								borderLeft: '30px solid transparent',
							}}
						></Stack>
						<StarIn style={{ position: 'absolute', top: '12px', right: '8px' }} />
					</>
				)}
				<Stack direction='row' gap='1rem'>
					<Avatar src={icon_file} />
					<Stack>
						<Typography fontFamily={'Inter'} fontSize='16px' fontWeight={700}>
							{company_name}
						</Typography>
						<Typography fontFamily={'Inter'} fontSize='12px' fontWeight={300}>
							{industry}
						</Typography>
					</Stack>
				</Stack>
				<Typography
					fontFamily={'Inter'}
					fontSize='14px'
					fontWeight={300}
					sx={{ display: '-webkit-box', WebkitLineClamp: '3', ' -webkit-box-orient': 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					{bio}
				</Typography>
				{/* <AboutWithIcons /> */}
				<Stack direction='row' gap='1rem' alignItems='center'>
					<Rating readOnly value={rating || 5} />
					<Typography fontFamily={'Inter'} fontSize='12px' fontWeight={400}>
						{reviews?.length} Review{reviews?.length > 1 ? 's' : ''}
					</Typography>
				</Stack>
			</Stack>
		</Paper>
	);
};

// const AboutWithIcons = ({ homes = 30, comm = 30, years, style }) => {
// 	return (
// 		<Stack gap='1rem' direction='row' alignItems='center'>
// 			<Stack direction='row' gap='5px' alignItems='center'>
// 				<HomeOutlined fontSize='12px' sx={{ fill: '#FFAA01' }} />
// 				<Typography fontFamily={'Inter'} fontSize='12px' fontWeight={400} sx={style}>
// 					{homes} homes
// 				</Typography>
// 			</Stack>
// 			<Stack direction='row' gap='5px' alignItems='center'>
// 				<LocationOnOutlined fontSize='12px' sx={{ fill: '#FFAA01' }} />
// 				<Typography fontFamily={'Inter'} fontSize='12px' fontWeight={400} sx={style}>
// 					{comm} communities
// 				</Typography>
// 			</Stack>
// 			<Stack direction='row' gap='5px' alignItems='center'>
// 				<AccessTimeOutlined fontSize='12px' sx={{ fill: '#FFAA01' }} />
// 				<Typography fontFamily={'Inter'} fontSize='12px' fontWeight={400} sx={style}>
// 					{years} years
// 				</Typography>
// 			</Stack>
// 		</Stack>
// 	);
// };

const LoadingScreen = () => {
	return (
		<Stack p='2rem 5rem' gap='1rem'>
			<Grid container spacing={2}>
				{[...Array(8)]
					.map((_, i) => i + 1)
					.map((item) => (
						<Grid item xs={4} key={item}>
							<Skeleton height={'220px'} width='100%' />
						</Grid>
					))}
			</Grid>
		</Stack>
	);
};
