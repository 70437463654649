import { AddBox, Close, Percent } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Button as NButton,
	Paper,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import base, { file as fileUploader } from '../apis';
import { ReactComponent as ImgPlac } from '../images/imgplaceholder.svg';
import { getPreview } from '../utils/previewHelper';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import ReactPDF from 'react-to-pdf';
import DateLocalizer from '../components/DateLocalizer';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const Button = styled(NButton)(({ theme }) => ({
	textTransform: 'capitalize',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#1378A5',
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const to2 = (number) => Number(number).toFixed(2);

const GenerateQuotes = () => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [currentQuote, setCurrentQuote] = useState(null);
	const [userInfo, setUserInfo] = useState(null);
	const { indPro } = useSelector((state) => state.subscriptions);
	const navigate = useNavigate();
	const targetRef = useRef();

	useEffect(() => {
		if (!indPro) {
			navigate('industry-prof-subscription');
		}
	}, [indPro, navigate]);
	useEffect(() => {
		//get user details
		//get invoice details
		(async () => {
			try {
				setIsLoading(true);
				const { data } = await base.get('register/ind-prof/profile/');
				setUserInfo(data);
				if (id) {
					const { data: quote } = await base.get(`professionals/quotes/${id}/`);
					setCurrentQuote(quote);

					//get quote details
				}
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		})();
	}, [id]);
	const [details, setDetails] = useState({
		tax_rate: '0',
		note: '',
		items: [
			{
				item: '',
				description: '',
				unit_price: '',
				quantity: '',
				amount: '',
			},
		],
	});

	const onAddNewItem = () => {
		setDetails((v) => ({
			...v,
			items: [
				...v.items,
				{
					item: '',
					description: '',
					unit_price: '',
					quantity: '',
					amount: '',
				},
			],
		}));
	};

	const removeItem = (index) => {
		setDetails((v) => {
			const items = v.items.filter((_, i) => i !== index);
			if (items.length === 0) {
				items.push({
					item: '',
					description: '',
					unit_price: '',
					quantity: '',
					amount: '',
				});
			}

			return { ...v, items };
		});
	};

	const handleChange = (item, value, index) => {
		setDetails((v) => {
			const items = v.items;
			items[index][item] = value;

			return { ...v, items };
		});
	};

	const [isSendLoading, setIsSendLoading] = useState(false);
	const typeId = useSelector((state) => state.auth.typeId);
	const [extraQuoteId, setextraQuoteId] = useState(null);
	const handleSendQuote = async () => {
		if (details?.items.some((i) => !i.quantity || !i.unit_price || !i.description)) {
			toast.warn('Please fill all details or remove');
			return;
		}
		if (!id) {
			const detailsSchema = z.object({
				first_name: z.string().min(2, 'First name must be filled'),
				last_name: z.string().min(2, 'Last name must be filled'),
				email: z.string().email(),
				phone: z.string().optional(),
			});

			const result = detailsSchema.safeParse(newDetails);
			if (!result.success) {
				toast.error(result.error.issues[0].message);
				return;
			}
		}
		try {
			setIsSendLoading(true);
			const body = {
				sub_total: to2(subtotal),
				tax: to2(taxAmount),
				tax_rate: details.tax_rate,
				quote_amount: to2(subtotal + taxAmount),
				total: to2(subtotal + taxAmount),
				items: details.items.map((item) => ({
					item: item.item,
					message: item.description,
					quantity: item.quantity,
					amount: item.unit_price,
					total_amount: to2(item.unit_price * item.quantity),
				})),
			};

			if (id) {
				body['quote'] = id;
			} else {
				const quote = {
					...newDetails,
					message: 'Created from my dashboard',
					profession_id: typeId,
				};
				body['quote_data'] = quote;
				body['is_without_quote'] = true;
			}

			if (details.note) body.message = details.note;
			const { data } = await base.post('professionals/quotes/reply/', body);
			setDetails({
				tax_rate: '0',
				note: '',
				items: [
					{
						item: '',
						description: '',
						unit_price: '',
						quantity: '',
						amount: '',
					},
				],
			});
			setNewDetails({
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
			});
			setextraQuoteId(data.id || id);

			toast.success('Quote sent successfully');
			setDetailsModalOpen(true);
			setFollowUp({
				date: null,
				message: '',
			});
		} catch (error) {
			toast.error('Could not send reply');
		} finally {
			setIsSendLoading(false);
		}
	};

	const subtotal = details.items.reduce((acc, cur) => acc + cur.unit_price * cur.quantity, 0);
	const taxAmount = subtotal * (details.tax_rate / 100);

	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [followUp, setFollowUp] = useState({
		date: null,
		message: '',
	});

	const filePickerRef = useRef(null);
	const handleImageChange = async (e) => {
		const { files } = e.target;
		const file = files[0];
		if (file) {
			let formData = new FormData();
			formData.append('icon_file', file);
			try {
				const { data } = await fileUploader.post(`register/ind-prof/create-profile/`, formData);
				setUserInfo(data);

				filePickerRef.current.value = '';
			} catch (error) {
				toast.error('Could not upload image');
			} finally {
			}
		}
	};

	const [content, setContent] = useState('');

	const onClickPreview = () => {
		const { company_name, address, icon_file, formatted_phone_number } = userInfo;
		const email = id ? currentQuote.email : newDetails.email;
		const name = id ? `${currentQuote?.first_name} ${currentQuote?.last_name}` : `${newDetails?.first_name} ${newDetails?.last_name}`;
		const phone_number = id ? currentQuote?.phone_number : newDetails.phone;
		const items = details.items.map((el) => ({
			...el,
			unit_price: Number(el.unit_price || 0).toFixed(2),
			amount: Number((el.quantity || 0) * (el.unit_price || 0)).toFixed(2),
		}));
		const tax_amount = Number(taxAmount).toFixed(2);
		const sub_total = Number(subtotal).toFixed(2);
		const Total = Number(subtotal + taxAmount).toFixed(2);

		setContent(
			getPreview(
				company_name,
				address,
				icon_file,
				formatted_phone_number,
				email,
				name,
				phone_number || '',
				items,
				details.tax_rate + '%',
				tax_amount,
				sub_total,
				Total
			)
		);
		setPreviewOpen(true);
	};

	const [isFollowupLoading, setIsFollowupLoading] = useState(false);
	const handleSubmitFollowup = async () => {
		if (!followUp.message) {
			toast.warn('Please enter a follow up message');
			return;
		}
		if (!followUp.date) {
			toast.warn('Please select a date');
			return;
		}
		setIsFollowupLoading(true);
		try {
			await base.post('professionals/follow-up/create/', {
				send_date: followUp.date?.format(),
				message: followUp.message,
				quote: id || extraQuoteId,
			});
			toast.success('Follow up saved successfully');
			setDetailsModalOpen(false);
			setFollowUp({
				date: null,
				message: '',
			});
		} catch (error) {
			toast.error('Could not save follow up');
		} finally {
			setDetailsModalOpen(false);
		}
	};

	const [newDetails, setNewDetails] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
	});

	if (isLoading) {
		return (
			<Stack sx={{ height: '90vh' }} alignItems='center' justifyContent={'center'}>
				<CircularProgress size={60} color='secondary' variant='indeterminate' />
			</Stack>
		);
	}

	return (
		<>
			<Dialog fullWidth maxWidth='sm' open={detailsModalOpen} onClose={() => setDetailsModalOpen(false)}>
				<DialogTitle
					sx={{
						background: 'linear-gradient(90deg, rgba(19, 120, 165, 0.15) 0%, rgba(0, 94, 169, 0) 100%)',
						boxShadow: '0px 0px 10px 0px #00000033',
					}}
				>
					<Typography fontWeight={600} fontSize={'20px'}>
						Set Follow Up Date
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack gap='1rem' mt='1rem'>
						<Typography>Date & Time</Typography>
						<DateLocalizer>
							<DateTimePicker
								value={followUp.date}
								onChange={(value) => setFollowUp((v) => ({ ...v, date: value }))}
								minDate={dayjs()}
								label='Date & Time'
							/>
						</DateLocalizer>
						<Typography>Follow Up Message</Typography>
						<TextField
							multiline
							rows={4}
							placholder='Follow up message'
							color='secondary'
							value={followUp.message}
							onChange={(e) => setFollowUp((v) => ({ ...v, message: e.target.value }))}
						/>
						<Stack direction='row' justifyContent={'flex-end'} gap='1rem' mt='2rem'>
							<Button variant='outlined' color='secondary' onClick={() => setDetailsModalOpen(false)}>
								Skip
							</Button>
							<LoadingButton
								variant='contained'
								color='secondary'
								sx={{ textTransform: 'capitalize' }}
								type='submit'
								onClick={handleSubmitFollowup}
								loading={isFollowupLoading}
							>
								Continue
							</LoadingButton>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
			{/* Preview modal */}
			<Dialog fullWidth maxWidth='md' open={previewOpen} onClose={() => setPreviewOpen(false)}>
				<DialogTitle>
					<Stack direction='row' justifyContent={'space-between'}>
						<Typography fontWeight={600} fontSize={'20px'}>
							Preview
						</Typography>
						<ReactPDF targetRef={targetRef} filename='requested-quote.pdf'>
							{({ toPdf }) => (
								<Button onClick={toPdf} variant='contained' color='secondary'>
									Download
								</Button>
							)}
						</ReactPDF>
						<IconButton onClick={() => setPreviewOpen(false)}>
							<Close fontSize='small' />
						</IconButton>
					</Stack>
				</DialogTitle>
				<DialogContent dividers>
					<div dangerouslySetInnerHTML={{ __html: content }} style={{ width: '100%', minHeight: '100vh' }} ref={targetRef}></div>
				</DialogContent>
			</Dialog>
			<Stack p='1rem 2rem' gap='1rem'>
				<Typography fontWeight={600} color='#3C3C3C'>
					Generate Quotes
				</Typography>
				<Typography color='#3C3C3C' fontSize={'13px'} mt='3rem'>
					Fill out the template below to generate a free quote. Once complete, you can save, download, or send the quote for free. For additional
					options like uploading a logo, click the link below.
				</Typography>
				<Stack gap='1rem' direction='row'>
					<Button variant='contained' color='secondary' onClick={onClickPreview}>
						Preview
					</Button>

					<LoadingButton
						variant='contained'
						sx={{ marginLeft: 'auto !important' }}
						color='secondary'
						onClick={handleSendQuote}
						loading={isSendLoading}
					>
						Send
					</LoadingButton>
				</Stack>
				<Grid container spacing={2}>
					<Grid item xs={7}>
						{id ? (
							<Stack direction='row' gap='1rem'>
								<Typography>
									To: {currentQuote?.first_name} {currentQuote?.last_name} ({currentQuote?.email})
								</Typography>
							</Stack>
						) : (
							<Grid container spacing={2} sx={{ width: '40%' }}>
								<Grid item xs={6}>
									<TextField
										placeholder='First name'
										color='secondary'
										size='small'
										value={newDetails.first_name}
										onChange={(e) => {
											setNewDetails((v) => ({ ...v, first_name: e.target.value }));
										}}
										fullWidth
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										placeholder='Last name'
										color='secondary'
										size='small'
										value={newDetails.last_name}
										onChange={(e) => {
											setNewDetails((v) => ({ ...v, last_name: e.target.value }));
										}}
										fullWidth
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										placeholder='Email'
										color='secondary'
										size='small'
										value={newDetails.email}
										onChange={(e) => {
											setNewDetails((v) => ({ ...v, email: e.target.value }));
										}}
										fullWidth
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										placeholder='Phone'
										color='secondary'
										size='small'
										value={newDetails.phone}
										onChange={(e) => {
											setNewDetails((v) => ({ ...v, phone: e.target.value }));
										}}
										fullWidth
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Grid item xs={5}>
						<Stack alignItems='flex-end' gap='1rem'>
							{userInfo?.icon_file ? (
								<img
									src={userInfo?.icon_file}
									alt='profile'
									style={{ width: '100px', aspectRatio: '1', borderRadius: '5px', cursor: 'pointer', objectFit: 'cover' }}
								/>
							) : (
								<>
									<Stack
										sx={{ width: '150px', aspectRatio: '1', borderRadius: '5px', border: '1px dashed grey', cursor: 'pointer' }}
										justifyContent='center'
										alignItems='center'
										onClick={() => {
											filePickerRef.current?.click();
										}}
									>
										<ImgPlac />
										<Typography fontSize={'9px'} fontWeight={500} color='secondary'>
											Click to choose file
										</Typography>
									</Stack>
									<input type='file' accept='image/png, image/jpeg' ref={filePickerRef} onChange={handleImageChange} />
								</>
							)}
							<Stack direction='row' gap='1rem' alignItems='center'>
								<Typography>Tax Rate</Typography>
								<TextField
									placeholder='Tax rate'
									size='small'
									color='secondary'
									value={details.tax_rate}
									type='number'
									// sx={{ width: '100px' }}
									inputProps={{
										max: 100,
										min: 0,
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Percent />
											</InputAdornment>
										),
									}}
									onChange={(e) => setDetails((v) => ({ ...v, tax_rate: e.target.value }))}
								/>
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Stack gap='1rem' alignItems='flex-start'>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 700 }} aria-label='customized table'>
									<TableHead>
										<TableRow>
											<StyledTableCell sx={{ width: '20% !important ' }}>Item</StyledTableCell>
											<StyledTableCell sx={{ width: '35% !important' }} align='center'>
												Description
											</StyledTableCell>
											<StyledTableCell sx={{ width: '15% !important ' }} align='center'>
												Unit Price
											</StyledTableCell>
											<StyledTableCell sx={{ width: '15% !important ' }} align='center'>
												Quantity
											</StyledTableCell>
											<StyledTableCell sx={{ width: '15% !important ' }} align='center'>
												Amount
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{details.items.map((row, index) => (
											<StyledTableRow key={index}>
												<StyledTableCell component='th' scope='row' sx={{ width: '20% !important ' }}>
													<Stack direction='row' alignItems='center'>
														<IconButton size='small' color='error' onClick={() => removeItem(index)}>
															<Close fontSize='small' sx={{ border: '1px solid red', borderRadius: '50%' }} />
														</IconButton>
														<Autocomplete
															freeSolo
															disableClearable
															options={userInfo?.services || []}
															placeholder='Select Item'
															fullWidth
															value={row.item}
															name='item'
															size='small'
															onInputChange={(e, value) => handleChange('item', value, index)}
															onChange={(e, value) => handleChange('item', value, index)}
															renderInput={(params) => <TextField size='small' color='secondary' {...params} fullWidth />}
														/>
													</Stack>
												</StyledTableCell>
												<StyledTableCell sx={{ width: '35% !important' }} align='right'>
													<TextField
														size='small'
														multiline
														fullWidth
														color='secondary'
														value={row.description}
														onChange={(e) => handleChange('description', e.target.value, index)}
													/>
												</StyledTableCell>
												<StyledTableCell sx={{ width: '15% !important ' }} align='right'>
													<TextField
														size='small'
														type='number'
														fullWidth
														color='secondary'
														value={row.unit_price}
														inputProps={{ min: 0.01 }}
														onChange={(e) => handleChange('unit_price', e.target.value, index)}
													/>
												</StyledTableCell>
												<StyledTableCell sx={{ width: '15% !important ' }} align='right'>
													<TextField
														size='small'
														type='number'
														fullWidth
														color='secondary'
														inputProps={{ min: 1 }}
														value={row.quantity}
														onChange={(e) => handleChange('quantity', e.target.value, index)}
													/>
												</StyledTableCell>
												<StyledTableCell sx={{ width: '15% !important ' }} align='right'>
													$ {Number(row.unit_price * row.quantity).toFixed(2)}
												</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							<Button startIcon={<AddBox />} color='secondary' variant='outlined' mt='1rem' onClick={onAddNewItem}>
								{' '}
								New Line
							</Button>
						</Stack>
					</Grid>
					<Grid item xs={7}>
						<Typography>Notes</Typography>
						<TextField
							placeholder='Notes'
							rows={5}
							fullWidth
							color='secondary'
							multiline
							inputProps={{ maxLength: 500 }}
							value={details.note}
							onChange={(e) => setDetails((v) => ({ ...v, note: e.target.value }))}
						/>
						<Typography sx={{ marginLeft: 'auto' }}>{details.note.length}/500</Typography>
					</Grid>
					<Grid item xs={5} alignItems='flex-end' justifyContent={'center'}>
						<Stack direction='row' gap='1rem' justifyContent='flex-end'>
							<Typography sx={{ width: '100px' }}>Subtotal</Typography>
							<Typography>{Number(subtotal).toFixed(2)}</Typography>
						</Stack>
						<Stack direction='row' gap='1rem' justifyContent='flex-end'>
							<Typography sx={{ width: '100px' }}>Tax</Typography>
							<Typography>{Number(taxAmount).toFixed(2)} </Typography>
						</Stack>
						<Divider />
						<Stack direction='row' gap='1rem' justifyContent='flex-end'>
							<Typography sx={{ width: '100px' }}>Total</Typography>
							<Typography>{Number(subtotal + taxAmount).toFixed(2)}</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</>
	);
};

export default GenerateQuotes;
