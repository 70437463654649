//api calls will be made here
import axios from 'axios';
import base from '../apis';
import { URL } from '../constants';
import {
	GET_TOKEN,
	GET_USER_DETAILS,
	GET_USER_DETAILS_FROM_LOCAL,
	SET_MODAL,
	SIGNOUT,
	SIGNUP,
	SWITCH_TO_SIGNIN,
	SWITCH_TO_SIGNUP,
	TOGGLE_AUTH_MODAL,
	TOGGLE_SIDEBAR,
} from './types';
import { toast } from 'react-toastify';
import { getSubscriptionStatus } from '../utils/functions';

//modal actions
export const toggleAuthModal = () => ({ type: TOGGLE_AUTH_MODAL });
export const switchToSignin = () => ({ type: SWITCH_TO_SIGNIN });
export const switchToSignup = () => ({ type: SWITCH_TO_SIGNUP });

//sidebar actions
export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR });

/** authentication actions */

export const GetToken =
	({ email, password }, callback, redirect) =>
	async (dispatch) => {
		dispatch({ type: 'START_LOADING' });
		axios
			.post(URL + 'auth/jwt/create/', { email: email?.toLowerCase(), password })
			.then((res) => {
				localStorage.setItem('atoken', res.data.access);
				localStorage.setItem('rtoken', res.data.refresh);
				dispatch({ type: GET_TOKEN, payload: res.data });

				dispatch(GetDetails(callback, redirect));
			})
			.catch((err) => {
				dispatch({ type: 'STOP_LOADING' });
				const message =
					err?.response?.data?.detail === 'No active account found with the given credentials'
						? 'Invalid email or password'
						: err?.response?.data?.detail || 'An error occured. Could not login.';
				toast.error(message);
			});
	};

export const GetDetails = (callback, redirect) => async (dispatch, getState) => {
	base
		.get('/auth/users/me', {
			headers: {
				Authorization: `JWT ${getState().auth.token.access}`,
			},
		})
		.then((res) => {
			dispatch({ type: GET_USER_DETAILS, payload: res.data });
			const data = res.data;
			if (data.role === 'ind_prof') {
				dispatch(getIndProfDetails(callback, redirect));
				return;
			}
			if (data.role === 'agent' || data.role === 'realty_agent') {
				dispatch(getAgentDetails(callback, redirect));
			} else {
				if (callback) {
					dispatch(getClientDetails(callback, redirect));
					// dispatch({ type: 'STOP_LOADING' });
					// callback(redirect?.from ? redirect.from : '/client-dashboard', { replace: true });
				} else {
					dispatch(getClientDetails(null, redirect));
					dispatch({ type: TOGGLE_AUTH_MODAL });
					// dispatch({ type: 'STOP_LOADING' });
				}
			}
		})
		.catch((err) => {
			dispatch({ type: 'STOP_LOADING' });
		});
};

export const googleOAuth = (code, navigate, isOneTap = false) => {
	return async (dispatch) => {
		try {
			dispatch({ type: 'START_LOADING' });
			const body = {};
			if (isOneTap) {
				body.id_token = code;
			} else {
				body.code = code;
				body.redirect_uri = window.location.origin;
			}
			const url = `${URL}${isOneTap ? 'core/one-tap-login/' : 'core/dj-rest-auth/google/'}`;
			const res = await axios.post(url, body);
			const access = res?.data?.access_token || res?.data?.access;
			const refresh = res?.data?.refresh_token || res?.data?.refresh;
			localStorage.setItem('atoken', access);
			localStorage.setItem('rtoken', refresh);
			dispatch({ type: GET_TOKEN, payload: { access: access, refresh: refresh } });

			dispatch(GetDetails(navigate));
		} catch (error) {
			dispatch({ type: 'STOP_LOADING' });
			if (error.response.data?.non_field_errors) {
				toast.error(error.response.data?.non_field_errors[0]);
			} else {
				toast.error('Sorry, something went wrong');
			}
		}
	};
};

export const facebookOAuth = (code, navigate) => {
	return async (dispatch) => {
		try {
			dispatch({ type: 'START_LOADING' });
			const url = `${URL}core/dj-rest-auth/facebook/`;
			const { data } = await base.post(url, { code: code, redirect_uri: window.location.origin + '/' });
			localStorage.setItem('atoken', data.access_token);
			localStorage.setItem('rtoken', data.refresh_token);
			dispatch({ type: GET_TOKEN, payload: { access: data.access_token, refresh: data.refresh_token } });
			dispatch(GetDetails(navigate));
		} catch (error) {
			dispatch({ type: 'STOP_LOADING' });
			if (error.response.data?.non_field_errors) {
				toast.error(error.response.data?.non_field_errors[0]);
			} else {
				toast.error('Sorry, something went wrong');
			}
		}
	};
};

export const getClientDetails = (navigate, redirect) => async (dispatch, getState) => {
	base
		.get('/register/clients/me', {
			headers: {
				Authorization: `JWT ${getState().auth.token.access}`,
			},
		})
		.then((res) => {
			// dispatch({ type: 'STOP_LOADING' });
			dispatch({ type: 'GET_TYPE_ID', payload: res.data });
			dispatch({ type: SET_MODAL });

			const nextRoute = localStorage.getItem('nextRoute');
			if (navigate) {
				const directionParams = {};
				if (nextRoute || redirect?.from) {
					localStorage.removeItem('nextRoute');
				} else {
					directionParams.replace = true;
				}
				dispatch({ type: 'SET_SIDEBAR', payload: document.body.clientWidth < 1200 });
				navigate(redirect?.from || nextRoute || '/client-dashboard', directionParams);
				dispatch({ type: 'STOP_LOADING' });
			} else {
				//change to match the role and set loading to false
				window.location.pathname = redirect?.from || nextRoute || '/client-dashboard';
				dispatch({ type: 'STOP_LOADING' });
				dispatch({ type: SET_MODAL });
			}
		})
		.catch((err) => {
			dispatch({ type: 'STOP_LOADING' });
		});
};

export const getAgentDetails = (navigate, redirect) => async (dispatch, getState) => {
	const id = getState().auth.id;
	base
		.get('/register/agents/me/', {
			headers: {
				Authorization: `JWT ${getState().auth.token.access}`,
			},
		})
		.then((res) => {
			dispatch({ type: 'STOP_LOADING' });
			dispatch({ type: 'GET_TYPE_ID', payload: res.data });
			dispatch({ type: 'START_LOADING' });

			base
				.get(`/register/subscriptions/`, { params: { user_id: id } })
				.then((res) => {
					dispatch({ type: 'SET_SUBSCRIPTONS', payload: getSubscriptionStatus(res.data) });
					dispatch({ type: 'SET_MODAL' });
					if (navigate) {
						const nextRoute = localStorage.getItem('nextRoute');
						const directionParams = {};
						if (nextRoute) {
							localStorage.removeItem('nextRoute');
						} else {
							directionParams.replace = true;
						}
						dispatch({ type: 'SET_SIDEBAR', payload: document.body.clientWidth < 1200 });
						navigate(redirect?.from || nextRoute || '/agent-dashboard', directionParams);
					} else {
						//change to match the role and set loading to false
						dispatch({ type: 'STOP_LOADING' });
						dispatch({ type: SET_MODAL });
					}
				})
				.catch((err) => {
					toast.error('Could not get subscriptions.');
				})
				.finally(() => {
					dispatch({ type: 'STOP_LOADING' });
				});
		})
		.catch((err) => {
			dispatch({ type: 'STOP_LOADING' });
		});
};

export const getIndProfDetails = (navigate, redirect) => async (dispatch, getState) => {
	const id = getState().auth.id;
	try {
		const { data } = await base.get('register/ind-prof/profile/', {
			headers: {
				Authorization: `JWT ${getState().auth.token.access}`,
			},
		});
		dispatch({ type: 'STOP_LOADING' });
		dispatch({ type: 'GET_TYPE_ID', payload: data });
		dispatch({ type: 'START_LOADING' });
		const { data: subscriptions } = await base.get(`/register/subscriptions/`, {
			params: {
				user_id: id,
			},
		});

		dispatch({ type: 'SET_SUBSCRIPTONS', payload: getSubscriptionStatus(subscriptions) });
		dispatch({ type: 'SET_MODAL' });
		if (navigate) {
			const nextRoute = localStorage.getItem('nextRoute');
			const directionParams = {};
			if (nextRoute) {
				localStorage.removeItem('nextRoute');
			} else {
				directionParams.replace = true;
			}
			dispatch({ type: 'SET_SIDEBAR', payload: document.body.clientWidth < 1200 });
			navigate(redirect?.from || nextRoute || '/industry-dashboard', directionParams);
		} else {
			dispatch({ type: 'STOP_LOADING' });
			dispatch({ type: SET_MODAL });
		}
	} catch (error) {
		dispatch({ type: 'STOP_LOADING' });
		dispatch({ type: SET_MODAL });
	}
};

export const signUpClient = (accountDetails, navigate, redirect) => async (dispatch) => {
	const { email, password, first_name, last_name, phone, zip } = accountDetails;

	let body = {
		phone: phone || '',
		zip_code: zip,
		user: {
			first_name,
			last_name,
			email: email?.toLowerCase(),
			password,
		},
	};

	dispatch({ type: 'START_LOADING' });
	axios
		.post(URL + 'register/clients/', body)
		.then(({ data }) => {
			dispatch({ type: SIGNUP, payload: data });
			dispatch(GetToken({ email, password }, () => dispatch({ type: TOGGLE_AUTH_MODAL, payload: data })));
		})
		.catch((err) => {
			dispatch({ type: 'STOP_LOADING' });
			if (err.response.data?.user) {
				for (const item in err.response.data.user) {
					toast.warn(err.response.data.user[item].toString());
				}
			}
		});
};

export const signUpAgent = (accountDetails, navigate) => async (dispatch) => {
	const { email, password, first_name, last_name, phone, zip } = accountDetails;
	const marketer_id = localStorage.getItem('affiliateId');
	const ghanaian_marketer = localStorage.getItem('ghanaian_marketer');
	const body = {
		phone: phone || '',
		zip_code: zip,
		category: 'real_estate_agent',
		user: {
			first_name,
			last_name,
			email: email?.toLowerCase(),
			password,
		},
	};
	if (marketer_id) {
		body.marketer_id = +marketer_id;
	}
	if (ghanaian_marketer) {
		body.ghanaian_marketer = +ghanaian_marketer;
	}
	dispatch({ type: 'START_LOADING' });

	axios
		.post(URL + 'register/agents/', body)
		.then(({ data }) => {
			const doExtra = () => {
				dispatch({ type: TOGGLE_AUTH_MODAL, payload: data });
				const nextRoute = localStorage.getItem('nextRoute');
				if (nextRoute) {
					localStorage.removeItem('nextRoute');
				}
				navigate('/', { replace: true });
			};
			dispatch({ type: SIGNUP, payload: data });
			dispatch(GetToken({ email, password }, doExtra));
		})
		.catch((err) => {
			dispatch({ type: 'STOP_LOADING' });
			if (err.response.data?.user) {
				for (const item in err.response.data.user) {
					toast.warn(err.response.data.user[item].toString());
				}
			}
		});
};

export const signUpIndustryProf = (accountDetails, navigate) => async (dispatch) => {
	const { email, password, first_name, last_name } = accountDetails;
	try {
		const { data } = await axios.post(URL + 'register/ind-prof/', {
			email,
			first_name,
			last_name,
			password,
			role: 'ind_prof',
		});
		const doExtra = () => {
			dispatch({ type: TOGGLE_AUTH_MODAL, payload: data });
			const nextRoute = localStorage.getItem('nextRoute');
			if (nextRoute) {
				localStorage.removeItem('nextRoute');
			}
			navigate(nextRoute || 'ind_prof', { replace: true });
		};
		dispatch({ type: SIGNUP, payload: { user: data } });
		dispatch(GetToken({ email, password }, doExtra));
	} catch (err) {
		if (err.response.data?.user) {
			for (const item in err.response.data.user) {
				toast.warn(err.response.data.user[item].toString());
			}
		}
	} finally {
		dispatch({ type: 'STOP_LOADING' });
	}
};

export const GetDetailsFromLocal = () => {
	const atoken = localStorage.getItem('atoken');
	const rtoken = localStorage.getItem('rtoken');
	const details = JSON.parse(localStorage.getItem('details'));

	if (atoken && rtoken && !!details) {
		return {
			type: GET_USER_DETAILS_FROM_LOCAL,
			payload: { ...details, rtoken, atoken },
		};
	} else
		return {
			type: 'noting',
		};
};

export const signout = () => {
	localStorage.clear();
	return {
		type: SIGNOUT,
	};
};
