import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import base from '../apis';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const status = (value) => {
	return value ? <CheckCircleOutline sx={{ fill: 'green' }} fontSize='small' /> : <HighlightOff sx={{ fill: 'red' }} fontSize='small' />;
};

const IndPayment = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [details, setDetails] = useState({
		account_id: null,
		charges_enabled: false,
		details_submitted: false,
		payouts_enabled: false,
		email: null,
	});

	const [isConnectionLoading, setIsConnectionLoading] = useState(false);
	const onConnectStripe = async () => {
		try {
			setIsConnectionLoading(true);
			const { data } = await base.post('professionals/connect-stripe/', {
				success_url: window.location.href,
				refresh_url: window.location.href,
			});

			window.location.href = data?.url;
		} catch (error) {
			toast.error('An error occurred');
		} finally {
			setIsConnectionLoading(false);
		}
	};

	const { indElite } = useSelector((state) => state.subscriptions);
	const navigate = useNavigate();

	useEffect(() => {
		if (!indElite) {
			navigate('/industry-prof-subscription');
		}
	}, [indElite, navigate]);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const { data } = await base.get('professionals/connected-account/');
				if (data?.message) {
					toast.warn('Click to connect your account');
					return;
				}
				setDetails(data);
			} catch (error) {
				toast.error('An error occurred');
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	if (isLoading) {
		return (
			<Stack sx={{ height: '90vh' }} alignItems='center' justifyContent={'center'}>
				<CircularProgress size={60} color='secondary' variant='indeterminate' />
			</Stack>
		);
	}
	if (!details.account_id) {
		return (
			<Stack sx={{ height: '90vh' }} alignItems='center' justifyContent={'center'}>
				<LoadingButton
					sx={{ textTransform: 'capitalize' }}
					color='secondary'
					onClick={onConnectStripe}
					variant='outlined'
					loading={isConnectionLoading}
				>
					Connect to Stripe
				</LoadingButton>
			</Stack>
		);
	}
	return (
		<Stack p='1rem' sx={{ minHeight: '100vh' }} gap='1rem'>
			<Stack direction='row' justifyContent={'space-between'}>
				<Typography fontSize='20px' fontWeight={700}>
					Stripe Integration Status
				</Typography>
				{!(details.details_submitted || details.charges_enabled || details.payouts_enabled) && (
					<LoadingButton
						size='small'
						color='error'
						variant='outlined'
						onClick={onConnectStripe}
						loading={isConnectionLoading}
						sx={{ marginBottom: '1rem', textTransform: 'capitalize' }}
					>
						Continue on-boarding
					</LoadingButton>
				)}
			</Stack>
			<Stack gap='1rem'>
				<Grid container spacing={2} sx={{ background: '#bebebe26', paddingY: '1rem' }}>
					<Grid item xs={3}>
						Email
					</Grid>
					<Grid item xs={3}>
						Charges Enabled
					</Grid>
					<Grid item xs={3}>
						Details Submitted
					</Grid>
					<Grid item xs={3}>
						Payout Submitted
					</Grid>
				</Grid>
				<Grid container spacing={2} justifyContent={'center'} alignItems='center'>
					<Grid item xs={3}>
						{details.email}
					</Grid>
					<Grid item xs={3}>
						{status(details.charges_enabled)}
					</Grid>
					<Grid item xs={3}>
						{status(details.details_submitted)}
					</Grid>
					<Grid item xs={3}>
						{status(details.payouts_enabled)}
					</Grid>
				</Grid>
			</Stack>
			{(details.details_submitted || details.charges_enabled || details.payouts_enabled) && (
				<Stack alignItems='center' justifyContent={'center'} gap='1rem' mt='2rem'>
					<Typography fontSize={30}>Need to change some details?</Typography>
					<Button variant='contained' href='https://dashboard.stripe.com/' target='_blank' color='secondary' sx={{ textTransform: 'capitalize' }}>
						Visit stripe
					</Button>
				</Stack>
			)}
		</Stack>
	);
};

export default IndPayment;
