import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import base from '../apis';
import { useDispatch } from 'react-redux';

const IndProfProfile = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [profileData, setProfileData] = useState(null);
	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const { data } = await base.get('register/ind-prof/user/profile/');
				setProfileData(data);
			} catch (error) {
				toast.error('Could not get details');
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const dispatch = useDispatch();

	const handleSave = async () => {
		setIsSaveLoading(true);
		const { first_name, last_name } = profileData;
		if (!first_name || !last_name) {
			toast.warn('Enter a first and last name');
			return;
		}
		try {
			const { data } = await base.patch('register/ind-prof/profile/', {
				first_name,
				last_name,
			});
			dispatch({ type: 'UPDATE_NAME', payload: { first_name: data.first_name, last_name: data.last_name } });
			toast.success('Profile updated successfully');
		} catch (error) {
			toast.error('Could not update details');
		} finally {
			setIsSaveLoading(false);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setProfileData((v) => ({
			...v,
			[name]: value,
		}));
	};

	if (isLoading) {
		return (
			<Stack sx={{ minHeight: '100vh' }} alignItems='center' justifyContent='center'>
				<CircularProgress color='secondary' />
			</Stack>
		);
	}

	return (
		<Stack gap='1rem' p='1rem' sx={{ minHeight: '100vh' }}>
			<Typography color='#1378A5' fontWeight={700} fontSize={30}>
				Edit Your Profile
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<Stack direction='row' gap='2rem' alignItems='center'>
						<Typography sx={{ width: '6rem' }}>First Name</Typography>
						<TextField
							placeholder='First name'
							size='small'
							fullWidth
							sx={{ width: '60%' }}
							color='secondary'
							value={profileData?.first_name}
							name='first_name'
							onChange={handleChange}
						/>
					</Stack>
				</Grid>
				<Grid item xs={8}>
					<Stack direction='row' gap='2rem' alignItems='center'>
						<Typography sx={{ width: '6rem' }}>Last Name</Typography>
						<TextField
							placeholder='Last name'
							size='small'
							fullWidth
							sx={{ width: '60%' }}
							color='secondary'
							value={profileData?.last_name}
							name='last_name'
							onChange={handleChange}
						/>
					</Stack>
				</Grid>
				<Grid item xs={8}>
					<Stack direction='row' gap='2rem' alignItems='center'>
						<Typography sx={{ width: '6rem' }}>Email</Typography>
						<TextField placeholder='Email' size='small' fullWidth sx={{ width: '60%' }} color='secondary' disabled value={profileData?.email} />
					</Stack>
				</Grid>
				{!profileData?.ind_prof && (
					<Grid item xs={4}>
						<Button href='my-listing' color='secondary'>
							Create Listing
						</Button>
					</Grid>
				)}
			</Grid>

			<LoadingButton
				loading={isSaveLoading}
				sx={{ textTransform: 'capitalize', width: 'fit-content', marginLeft: 'auto', marginRight: '30%' }}
				variant='contained'
				color='secondary'
				onClick={handleSave}
			>
				Save
			</LoadingButton>
		</Stack>
	);
};

export default IndProfProfile;
