import { AddComment, Bookmark, Comment, CreditCard, Dashboard, Devices, Person, Store } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './leftnavbar.scss';
import Lockable from '../Lockable/Lockable';
import LockIcon from '@mui/icons-material/Lock';

const IndProfLeftNav = ({ onClose }) => {
	const Username = useSelector((state) => state.auth.first_name);
	const { indPro, indElite, indUltra } = useSelector((state) => state.subscriptions);
	const any = indPro || indElite || indUltra;

	return (
		<div className='left_navbar'>
			<h1 style={{ fontSize: '1.6rem', margin: '2rem 0' }}>Welcome, {Username}!</h1>
			<div className='nav_links_wrapper'>
				<NavLink className='nav_link ' to='industry-dashboard' onClick={onClose}>
					<Dashboard className='nav_icon' />
					<p>Dashboard</p>
				</NavLink>

				<Lockable subscribed={indElite} link='/industry-prof-subscription' title='Click to upgrade'>
					<NavLink className='nav_link ' to='/industry-website' onClick={onClose}>
						<Devices className='nav_icon' />
						<p>Industry Website</p>
						{!indElite && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
					</NavLink>
				</Lockable>
				<Lockable subscribed={any} link='/industry-prof-subscription' title='Click to upgrade'>
					<NavLink className='nav_link ' to='/requested-quotes' onClick={onClose}>
						<Comment className='nav_icon' />
						<p>Quotes</p>
						{!any && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
					</NavLink>
				</Lockable>
				<Lockable subscribed={any} link='/industry-prof-subscription' title='Click to upgrade'>
					<NavLink className='nav_link ' to='/new-quote' onClick={onClose}>
						<AddComment className='nav_icon' />
						<p>Generate New Quote</p>
						{!any && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
					</NavLink>
				</Lockable>
				<Lockable subscribed={any} link='/industry-prof-subscription' title='Click to upgrade'>
					<NavLink className='nav_link ' to='/follow-ups' onClick={onClose}>
						<Bookmark className='nav_icon' />
						<p>Follow Ups</p>
						{!any && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
					</NavLink>
				</Lockable>
				<NavLink className='nav_link ' to='/my-listing' onClick={onClose}>
					<Store className='nav_icon' />
					<p>Business Listing</p>
				</NavLink>
				<Lockable subscribed={indElite || indUltra} link='/industry-prof-subscription' title='Click to upgrade'>
					<NavLink className='nav_link ' to='ind-payment' style={{ pointerEvents: any ? 'all' : 'none' }} onClick={onClose}>
						<CreditCard className='nav_icon' />
						<p>Payment</p>
						{!(indElite || indUltra) && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
					</NavLink>
				</Lockable>
				<NavLink className='nav_link ' to='ind_prof' onClick={onClose}>
					<Person className='nav_icon' />
					<p>Profile</p>
				</NavLink>

				<div className='hr'>
					<hr />
				</div>
			</div>
		</div>
	);
};

export default IndProfLeftNav;
