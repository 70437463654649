import { ThemeProvider } from '@emotion/react';
import { Avatar, Button, createTheme, Grid, IconButton, Stack, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { Fragment } from 'react';
import { Link as RLink } from 'react-router-dom';
import RequestAQuote from '../components/RequestAQuote';
import Grad2 from '../images/gradindTemp2_1.png';
import Grad1 from '../images/gradindTemp2_2.png';
import Grad3 from '../images/gradindTemp2_3.png';
import BG from '../images/indprodtemp2.jpg';
import HardBig from '../images/indProfTemp2/hardscapeBig.png';
import { ReactComponent as HardLil } from '../images/indProfTemp2/hardscapeLil.svg';
import InstallBig from '../images/indProfTemp2/installationBig.png';
import { ReactComponent as InstallLil } from '../images/indProfTemp2/installationLil.svg';
import IrrigBig from '../images/indProfTemp2/irrigationBig.png';
import { ReactComponent as IrrigLil } from '../images/indProfTemp2/irrigationLil.svg';
import MaintBig from '../images/indProfTemp2/maintenanceBig.png';
import { ReactComponent as MaintLil } from '../images/indProfTemp2/maintenanceLil.svg';
import { ReactComponent as CardIcon } from '../images/indProfTemp2/Card.svg';
import { ReactComponent as TreeIcon } from '../images/indProfTemp2/Tree.svg';
import { ReactComponent as TrophyIcon } from '../images/indProfTemp2/Trophy.svg';
import SideBg from '../images/indProfTemp2/sideBG.jpg';
import { ReactComponent as FBIcon } from '../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../images/linkedInfillable.svg';
import { ReactComponent as YTIcon } from '../images/youtubefillable.svg';
import { ReactComponent as TWIcon } from '../images/twitterfillable.svg';

import Innov from '../images/innovative_ind2.png';
import { theme } from '../theme';

//!Landscaping
// https://www.figma.com/design/Q83dnbULW6YZeYlFf9qbZX/Premier-Agent?node-id=679-16629&t=3DOJ2qQWiK7y4nIT-4

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Inter, serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#365BC6',
		},
		secondary: {
			main: '#FFAA01',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#365BC6',
	secondaryColor: '#FFAA01',
});

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Inter, serif',
		scrollMarginTop: '100px',
		minHeight: '100vh',
		position: 'relative',
		// background: '#F5F6EF',
		paddingLeft: '10%',
		paddingRight: '10%',
		gap: '3rem',
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: 600,

		color: '#003F2E',
	},
	color: {
		'& *': {
			color: '#2D746D',
		},
	},
}));

const Link = styled(RLink)(({ theme }) => ({
	cursor: 'pointer',
}));

const navItems = [
	{ label: 'Home', value: '#home' },
	{ label: 'Request A Quote', value: '#quote' },
	{ label: 'About Us', value: '#about-us' },
];

const cutout = [
	{
		SideImage: <InstallLil />,
		image: InstallBig,
		text: 'Unleash the beauty of nature with our creative garden design installation solutions.',
		title: 'Installation',
	},
	{
		SideImage: <MaintLil />,
		image: MaintBig,
		text: 'Keep your lawn lush and vibrant with our expert maintenance services.',
		title: 'Maintenance',
	},
	{
		SideImage: <HardLil />,
		image: HardBig,
		text: 'Elevate your outdoor living spaces with stunning hardscape installations',
		title: 'Hardscape',
	},
	{
		SideImage: <IrrigLil />,
		image: IrrigBig,
		text: 'Ensure your landscape stays green and healthy with efficient irrigation solutions',
		title: 'Irrigation',
	},
];

const whyChooseUs = [
	{
		icon: <CardIcon />,
		text: 'ICPI & NCMA certified installer',
	},
	{
		icon: <TreeIcon />,
		text: 'Sustainable landscape designs',
	},
	{
		icon: <TrophyIcon />,
		text: 'Award-Winning landscape services',
	},
];

const socials = [
	{
		key: 'facebook',
		Icon: <FBIcon fill='#003F2E' />,
	},
	{
		key: 'twitter',
		Icon: <TWIcon fill='#003F2E' />,
	},
	{ key: 'instagram', Icon: <IGIcon fill='#003F2E' /> },
	{
		key: 'linkedin',
		Icon: <LIIcon fill='#003F2E' />,
	},
	{
		key: 'youtube',
		Icon: <YTIcon fill='#003F2E' />,
	},
];

const IndProfTemp2 = ({ address, icon_file, company_name, id, formatted_phone_number, user, ...profDetails }) => {
	const classes = useStyles();

	return (
		<ThemeProvider theme={pageTheme}>
			<Stack className={classes.root}>
				<img src={Grad1} style={{ position: 'absolute', top: '0', right: '0', zIndex: -1 }} alt='gradient' />
				<img src={Grad2} style={{ position: 'absolute', top: '0', left: '0', zIndex: -1 }} alt='gradient' />
				<img src={Grad3} style={{ position: 'absolute', bottom: '0', left: '0', zIndex: -1 }} alt='gradient' />
				<Stack px='6rem' py='1rem' alignItems='center' justifyContent='space-between' direction='row'>
					<Stack direction='row' gap='1rem' alignItems={'center'}>
						<Avatar src={icon_file} />
						<Typography color='#003F2E' fontWeight={700} fontSize='30px'>
							{company_name}
						</Typography>
					</Stack>
					<Stack direction='row' gap='2rem'>
						{navItems.map((item) => (
							<Link to={item.value} className={classes.leftLinks} underline='none' key={item.value}>
								{item.label}
							</Link>
						))}
					</Stack>
				</Stack>
				<Stack justifyContent={'center'} gap='2rem' sx={{ width: '100%', margin: 'auto' }} alignItems='center'>
					<img src={Innov} alt='innov' style={{ margin: 'auto' }} />
					<Typography sx={{ textWrap: 'balance' }} color='#365BC6' fontWeight={600} fontSize={'90px'} textAlign={'center'}>
						Best Landscape Design & Maintenance
					</Typography>
					<Typography sx={{ textWrap: 'balance' }} color='#365BC6' fontWeight={400} fontSize={'26px'} textAlign={'center'}>
						We are proud to be a responsible and sustainable lawn care company, dedicated to preserving the environment and the health of your lawn
					</Typography>
					<Button sx={{ textTransform: 'capitalize', width: 'fit-content' }} href='#quote' disableElevation disableRipple variant='contained'>
						Request a Quote
					</Button>
				</Stack>
				<img
					src={BG}
					alt='background'
					style={{ margin: 'auto', borderRadius: '67px', width: '100%', marginTop: '3rem', aspectRatio: 2, objectFit: 'cover' }}
				/>

				<Stack gap='1rem' mt='7rem !important' sx={{ width: '100%', margin: 'auto' }}>
					<Typography sx={{ textWrap: 'balance' }} color='#C43338' fontWeight={400} textAlign={'center'} textTransform={'uppercase'}>
						What we do
					</Typography>
					<Typography sx={{ textWrap: 'balance' }} color='#365BC6' fontWeight={400} fontSize={'46px'} textAlign={'center'}>
						Get more value from our landscaper
					</Typography>
					<Typography sx={{ textWrap: 'balance' }} color='#365BC6' fontWeight={400} fontSize={'26px'} textAlign={'center'}>
						Our technicians use state-of-the-art equipment and eco-friendly products to keep your lawn healthy and creating stunning and functional
						landscapes.
					</Typography>
				</Stack>

				<Grid container spacing={4}>
					{cutout.map((item) => (
						<Grid item xs={3} key={item.title}>
							<CutoutCard {...item} />
						</Grid>
					))}
				</Grid>

				<Grid container spacing={6} alignItems={'center'}>
					<Grid item xs={6}>
						<Stack alignItems='center' sx={{ height: '100%' }}>
							<img src={SideBg} alt='side' style={{ borderRadius: '40px 266.67px 40px 266.67px', width: '100%', aspectRatio: '1.12' }} />
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack gap='1rem' mt='7rem !important' sx={{ width: '100%', margin: 'auto' }}>
							<Typography sx={{ textWrap: 'balance' }} color='#365BC6' fontWeight={400} textTransform={'uppercase'}>
								Why choose us
							</Typography>
							<Typography sx={{ textWrap: 'balance' }} color='#365BC6' fontWeight={600} fontSize={'48px'}>
								Take the hassle out of garden maintenance
							</Typography>
							<Typography sx={{ textWrap: 'balance' }} color='#365BC6' fontWeight={400} fontSize={'21px'}>
								We assist homeowners in saving time and effort by designing and upkeeping landscapes, lawns, and outdoor living areas that garner
								admiration. Our team comprises skilled maintenance professionals certified well-versed in effective landscape upkeep services to
								ensure the year-round health of your property.
							</Typography>
							{whyChooseUs.map((item) => (
								<Stack direction='row' gap='1.5rem' alignItems={'center'} key={item.text}>
									{item.icon}
									<Typography color='#365BC6' fontWeight={600} fontSize={'21px'}>
										{item.text}
									</Typography>
								</Stack>
							))}
						</Stack>
					</Grid>
				</Grid>

				<Stack sx={{ width: '80%', margin: 'auto', minWidth: '300px' }} gap='2rem' mt='2rem' pt='5rem' id='quote'>
					<Typography fontSize='30px' textAlign={'center'}>
						Want A Quote?
					</Typography>
					<RequestAQuote withMargin={false} id={id} />
				</Stack>
				<Grid container spacing={2} width={'70%'} mx='auto' my='3rem' className={classes.color}>
					<Grid item xs={4}>
						<Stack gap='1rem'>
							<Avatar src={icon_file} />
							<Typography color='#003F2E' fontWeight={700} fontSize='30px'>
								{company_name}
							</Typography>
							<Stack direction='row' gap='1rem'>
								{socials.map((social) => (
									<Fragment key={social.key}>
										{profDetails?.[social.key] && (
											<IconButton href={profDetails?.[social.key]} target='_blank'>
												{social.Icon}
											</IconButton>
										)}
									</Fragment>
								))}
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={4}>
						<Stack gap='1rem'>
							<Typography color='#003F2E !important' fontWeight={600} fontSize='20px'>
								{address}
							</Typography>
							<Typography sx={{ cursor: 'pointer' }} component='a' href={`tel: ${formatted_phone_number}`}>
								Phone: {formatted_phone_number}
							</Typography>
							<Typography component='a' href={`tel: ${formatted_phone_number}`} sx={{ cursor: 'pointer' }}>
								Email: {user?.email}
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={4}>
						<Stack gap='1rem'>
							<Typography color='#003F2E !important' fontWeight={600} fontSize='20px'>
								Navigation
							</Typography>
							{navItems.map((item) => (
								<Link to={item.value} underline='none' key={item.value} style={{ fontWeight: 400 }}>
									{item.label}
								</Link>
							))}
						</Stack>
					</Grid>
					<Grid item xs={12} mt='3rem'>
						<Stack direction={'row'} justifyContent={'space-between'}>
							<Typography>&copy; {new Date().getFullYear()} BPO Tech All rights reserved.</Typography>
							<Stack direction='row' gap='1rem'>
								<Link to='/privacy-policy'>Privacy Policy</Link>
								<Link to='/terms-of-service'>Terms and Conditions</Link>
							</Stack>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</ThemeProvider>
	);
};

export default IndProfTemp2;

const CutoutCard = ({ title, image, text, SideImage }) => {
	return (
		<Stack sx={{ height: '100%' }}>
			<Stack direction='row'>
				<Typography
					sx={{ background: 'white', p: '1rem', flex: 1, borderTopLeftRadius: '5px', borderTopRightRadius: '5px', color: '#365BC6' }}
					fontWeight={600}
					fontSize='27px'
				>
					{title}
				</Typography>
				<Stack sx={{ width: '20%' }} justifyContent={'center'} alignItems='center'>
					{SideImage}
				</Stack>
			</Stack>
			<Stack
				sx={{ background: 'white', p: '1.2rem', borderTopLeftRadius: 0, borderRadius: '5px', height: '100%' }}
				gap='1rem'
				justifyContent={'space-between'}
			>
				<Typography color='#365BC6' fontSize='18px'>
					{text}
				</Typography>
				<img src={image} alt={title} style={{ borderRadius: '11px', width: '100%', aspectRatio: '1.18' }} />
			</Stack>
		</Stack>
	);
};
