import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Card, Divider, Grid, Radio, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Checkbox from '../components/Checkbox/Checkbox';
import Spinner from '../components/Spinner/Spinner';
// import { farmPackages as packages } from '../../constants';
import Footer from '../components/Footer/Footer';
import BPOLogo from '../images/bpoVerticalLogo.webp';
import './ChoosePackage/choosepackage.scss';
import './ChooseSubscription/choosesubscription.scss';
// import './bposub.scss';
import { useSelector } from 'react-redux';
import base from '../apis';
import { formatNumber } from '../utils/functions';

export const packages = [
	{
		title: 'Pro (SET UP FEE NOT INCLUDED $99)',
		benefits: ['Business Listed as Featured Business on BPOHomes.com', 'Connect Your Personal Website', 'Quote Generator'],
	},
	{
		title: 'Elite (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			'Business Listed as Featured Business on BPOHomes.com',
			'Connect Your Personal Website',
			'Quote Generator',
			'Professional Business Website Included (12.00 Yearly Domain Purchase Business with GoDaddy Not Included)',
			'Web Hosting Included',
			'Website Stripe Payment Processing Included',
			'Assist with Google Business Page Set Up',
		],
	},
	{
		title: 'Ultra (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			'Business Listed as Featured Business on BPOHomes.com',
			'Connect Your Personal Website',
			'Quote Generator',
			'Professional Business Website Included (12.00 Yearly Domain Purchase Business with GoDaddy Not Included)',
			'Web Hosting Included',
			'Website Stripe Payment Processing Included',
			'Assist with Google Business Page Set Up',
			'Dedicated Local Business Line',
			'Live Virtual Assistant 8am-5pm Client Business Hours Only',
		],
	},
];

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
	card: {
		padding: '20px',
	},
	titleText: {
		// fontSize: '1.5rem',
		fontWeight: 'bold',
		color: theme.secondaryColor,
		textTransform: 'uppercase',
		textAlign: 'center',
		display: 'block',
	},
	divider: {
		background: theme.secondaryColor,
		height: '2px',
		width: '5rem',
		margin: '10px auto ',
		display: 'block',
		border: 'none !important',
	},
	checkbox: {
		color: '#1378a5',
		fontSize: 'small',
	},
}));

function IndSubscriptionPayment() {
	const classes = useStyles();
	const navigate = useNavigate();
	const [prices, setPrices] = useState({
		base_setup_price_id: '',
		pro: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
		elite: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
		ultra: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
	});

	const role = useSelector((state) => state.auth.role);

	useEffect(() => {
		setIsLoading(true);
		const controller = new AbortController();
		if (role !== 'ind_prof') {
			toast.warn('Only Industry professionals can make payment');
			navigate(role === 'client' ? '/client-dashboard' : '/agent-dashboard');
			return;
		}

		base
			.get('/register/list_prices/', {
				signal: controller.signal,
			})
			.then((res) => {
				if (!res) return;
				const out = res?.data?.flat();
				//add product=bpo_combo_farm to metadata
				const indProducts = out?.filter((item) => item.metadata?.product === 'industry_professional' && item.active);
				const proMonthly = indProducts.find((item) => item.metadata?.package_grade === 'pro' && item.recurring?.interval === 'month');
				const eliteMonthly = indProducts.find((item) => item.metadata?.package_grade === 'elite' && item.recurring?.interval === 'month');
				const ultraMonthly = indProducts.find((item) => item.metadata?.package_grade === 'ultra' && item.recurring?.interval === 'month');
				const proYearly = indProducts.find((item) => item.metadata?.package_grade === 'pro' && item.recurring?.interval === 'year');
				const eliteYearly = indProducts.find((item) => item.metadata?.package_grade === 'elite' && item.recurring?.interval === 'year');
				const ultraYearly = indProducts.find((item) => item.metadata?.package_grade === 'ultra' && item.recurring?.interval === 'year');
				const baseSetup = out.find((item) => item.metadata?.package === 'setup_fee');
				// const addOnSetup = out.find((item) => item.metadata?.product === 'farm_addon_setup');

				const toSet = {
					base_setup_price_id: baseSetup?.id,
					base_setup_price_amount: formatNumber(baseSetup?.unit_amount / 100),

					pro: {
						monthly: {
							amount: formatNumber(proMonthly?.unit_amount / 100),
							priceId: proMonthly?.id,
						},
						yearly: {
							amount: formatNumber(proYearly?.unit_amount / 100),
							priceId: proYearly?.id,
						},
					},
					elite: {
						monthly: {
							amount: formatNumber(eliteMonthly?.unit_amount / 100),
							priceId: eliteMonthly?.id,
						},
						yearly: {
							amount: formatNumber(eliteYearly?.unit_amount / 100),
							priceId: eliteYearly?.id,
						},
					},
					ultra: {
						monthly: {
							amount: formatNumber(ultraMonthly?.unit_amount / 100),
							priceId: ultraMonthly?.id,
						},
						yearly: {
							amount: formatNumber(ultraYearly?.unit_amount / 100),
							priceId: ultraYearly?.id,
						},
					},
				};
				setPrices(toSet);
			})
			.catch((err) => {
				toast.error('Could not get prices');
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			controller.abort();
		};
	}, [navigate, role]);

	const [isLoading, setIsLoading] = useState(false);

	const [isMonthly, setIsMonthly] = useState(true);

	const [selectedPackages, setSelectedPackages] = useState({
		0: '',
		1: '',
		2: '',
	});

	const [packageName, setPackageName] = useState(null);

	const handleSelected = (index, id, packInd) => {
		let packageName = 'pro';
		if (packInd === 1) packageName = 'elite';
		if (packInd === 2) packageName = 'ultra';
		setPackageName(packageName);

		setSelectedPackages((current) => ({ ...current, [index]: id }));
	};

	const [termsAgreed, setTermsAgreed] = useState(false);
	const [termsAgreed1, setTermsAgreed1] = useState(false);

	const handleIsMonthly = (e, value) => {
		setIsMonthly(value);
		setSelectedPackages({
			0: '',
			1: '',
			2: '',
		});
	};

	if (isLoading) {
		return <PlanSkeleton />;
	}

	return (
		<>
			<Elements stripe={stripePromise}>
				{isLoading && <Spinner />}
				<div className='choose-package_header'>
					<Link to={'/agent-dashboard'}>
						<img alt='Logo' src={BPOLogo} />
					</Link>
					<div style={{ textAlign: 'right' }}>
						<p>Call Sales: 1.888.616.5270</p>
						<p>9:00 AM to 6:30 PM PST, Monday to Friday</p>
					</div>
				</div>

				{[0].map((territory, index) => (
					<Stack gap='1rem' key={index}>
						<Box display={'flex'} justifyContent='center' gap='20px' alignItems='center' p='1rem'>
							<ToggleButtonGroup size='small' color='secondary' value={isMonthly} exclusive onChange={handleIsMonthly}>
								<ToggleButton value={true}>Monthly</ToggleButton>
								<ToggleButton value={false}>Annual</ToggleButton>
							</ToggleButtonGroup>
						</Box>
						<Grid container spacing={2} px='3rem'>
							{packages.map((item, packageIndex) => {
								let value;
								switch (packageIndex) {
									case 0:
										value = 'pro';
										break;
									case 1:
										value = 'elite';
										break;
									case 2:
										value = 'ultra';
										break;

									default:
										break;
								}
								const price =
									prices?.[value]?.[isMonthly ? 'monthly' : 'yearly']?.amount || prices?.[value]?.[isMonthly ? 'monthly' : 'yearly']?.amount;
								const id = prices?.[value][isMonthly ? 'monthly' : 'yearly']?.priceId;
								return (
									<Grid item xs={4} key={item.title + item.title}>
										<Card
											id={id}
											sx={{
												borderColor: selectedPackages[index] === id ? 'var(--primary-color)' : 'white',
												borderWidth: '1px',
												borderStyle: 'solid',
												cursor: 'pointer',
												height: '100%',
											}}
											className={classes.card}
											onClick={() => handleSelected(index, id, packageIndex)}
										>
											<Typography paragraph gutterBottom className={classes.titleText}>
												{item.title}
											</Typography>
											<Divider className={classes.divider} />
											<Box display='flex' gap='20px' alignItems='center' width='fit-content' mx='auto' mb='30px !important'>
												<Radio checked={selectedPackages[index] === id} size={'small'} value={id} name='radio-buttons' color='secondary' />
												<Typography display='flex' gap='10px'>
													<Typography variant='span' sx={{ fontWeight: 'bolder', fontSize: '20px' }}>
														$ {price}
													</Typography>
													per {isMonthly ? 'month' : 'year'}
												</Typography>
											</Box>
											{item.benefits.map((benefit, benIndex) => (
												<Box display='flex' alignItems='center' gap='10px' key={benefit} mb={'20px'}>
													<CheckCircleOutlineIcon className={classes.checkbox} />
													<Typography fontSize='15px'>{benefit}</Typography>
												</Box>
											))}
										</Card>
									</Grid>
								);
							})}
						</Grid>
					</Stack>
				))}
				<Box display='flex' justifyContent='center'>
					<Box display='flex' alignItems='center' flexDirection='column' mt='2rem' sx={{ maxWidth: '85%' }} gap='1rem'>
						<Checkbox
							text='By checking this you have agreed to the terms and conditions'
							checked={termsAgreed}
							setChecked={(e) => setTermsAgreed(e.target.checked)}
							labelStyle={{ marginRight: 'auto' }}
						/>
						<div>
							<Checkbox
								labelStyle={{ marginRight: 'auto', display: 'inline' }}
								checked={termsAgreed1}
								setChecked={(e) => setTermsAgreed1(e.target.checked)}
								text={`By providing my phone number to “BPO Homes”, I agree and acknowledge that “BPO Homes ” may send text messages to my wireless phone number for any purpose. Message and data rates may apply. Message frequency will vary, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled please visit `}
							/>
							<NavLink to='/privacy-policy' className='blue_link'>
								https://bpotech.io/privacy-policy
							</NavLink>
						</div>
						<p style={{ margin: 'auto' }}>
							By placing the order you agree with our{' '}
							<NavLink to={`/terms-of-service`} className='blue_link'>
								Terms & Conditions
							</NavLink>{' '}
							and{' '}
							<NavLink to='/privacy-policy' className='blue_link'>
								Privacy Policy
							</NavLink>
						</p>
						<StripePay
							base_setup_price_id={prices?.base_setup_price_id}
							isMonthly={isMonthly}
							isChecked={termsAgreed && termsAgreed1}
							selectedPackage={selectedPackages?.[0]}
							packageName={packageName}
						/>
					</Box>
				</Box>
				<Footer />
			</Elements>
		</>
	);
}

export default IndSubscriptionPayment;

const StripePay = ({ base_setup_price_id, isMonthly, isChecked, selectedPackage, packageName }) => {
	const stripe = useStripe();

	const confirmPayment = () => {
		if (!isChecked) {
			toast.warn('Please agree to the terms and conditions');
			return;
		}
		if (!selectedPackage) {
			toast.warn('Please select a package');
			return;
		}
		const body = {
			interval: isMonthly ? 'monthly' : 'yearly',
			price_data: [
				{
					price: base_setup_price_id,
					quantity: 1,
				},
				{
					price: selectedPackage,
					quantity: 1,
				},
			],
		};

		base
			.post('/register/create_subscription/', {
				...body,
				cancel_url: window.location.href,
				success_url: window.location.origin + `/payment_success?type=industry_professional&`,
				payment_method_types: ['card'],
				mode: 'subscription',
				package: `industry_professional_${packageName}`,
			})
			.then((res) => {
				if (!res?.data?.sessionId) {
					toast.error('Something went wrong');
					return;
				}
				stripe.redirectToCheckout({
					sessionId: res?.data?.sessionId,
				});
			})
			.catch((err) => {
				toast.error('Could not process payment');
			});
	};

	return (
		<Button onClick={confirmPayment} variant='contained' color='secondary' mb='1rem' sx={{ textTransform: 'capitalize' }}>
			Confirm payment
		</Button>
	);
};

const PlanSkeleton = () => {
	return (
		<Box sx={{ padding: 4 }}>
			<Grid container spacing={4}>
				{/* Pro Plan Skeleton */}
				<Grid item xs={12} md={4}>
					<Box sx={{ padding: 3, border: '1px solid #ccc', borderRadius: 2, height: '90vh' }}>
						<Skeleton variant='text' width={150} height={30} />
						<Skeleton variant='text' width={60} height={40} sx={{ marginTop: 2, marginBottom: 2 }} />
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
							<Skeleton variant='text' width='80%' height={20} />
							<Skeleton variant='text' width='90%' height={20} />
							<Skeleton variant='text' width='70%' height={20} />
						</Box>
					</Box>
				</Grid>

				{/* Elite Plan Skeleton */}
				<Grid item xs={12} md={4}>
					<Box sx={{ padding: 3, border: '1px solid #ccc', borderRadius: 2, height: '90vh' }}>
						<Skeleton variant='text' width={150} height={30} />
						<Skeleton variant='text' width={60} height={40} sx={{ marginTop: 2, marginBottom: 2 }} />
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
							<Skeleton variant='text' width='80%' height={20} />
							<Skeleton variant='text' width='90%' height={20} />
							<Skeleton variant='text' width='70%' height={20} />
							<Skeleton variant='text' width='85%' height={20} />
							<Skeleton variant='text' width='75%' height={20} />
						</Box>
					</Box>
				</Grid>

				{/* Ultra Plan Skeleton */}
				<Grid item xs={12} md={4}>
					<Box sx={{ padding: 3, border: '1px solid #ccc', borderRadius: 2, height: '90vh' }}>
						<Skeleton variant='text' width={150} height={30} />
						<Skeleton variant='text' width={60} height={40} sx={{ marginTop: 2, marginBottom: 2 }} />
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
							<Skeleton variant='text' width='80%' height={20} />
							<Skeleton variant='text' width='90%' height={20} />
							<Skeleton variant='text' width='70%' height={20} />
							<Skeleton variant='text' width='85%' height={20} />
							<Skeleton variant='text' width='75%' height={20} />
							<Skeleton variant='text' width='65%' height={20} />
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
